import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import store from './store';
import { Redirect, Route, Router } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import Menu from './Menu/Menu';
import PayoutFile from './PayoutFiles/PayoutFile';
import PayoutFiles from './PayoutFiles/PayoutFiles';
import Controversial from './Controversial/Controversial';
import Invoices from './Invoice/Invoices';
import Invoice from './Invoice/Invoice';
import InvoiceCreate from './Invoice/InvoiceCreate';
import ProjectTask from './Project/Project';
import { auth } from './auth';
import history from './history';
import Payout from './Payout/Payout';
import User from './User/User';
import Refund from './Refund/Refund';
import Projects from './Projects/Projects';
import Organizations from './Organizations/Organizations';
import Accounting from './Accounting/Accounting';
import ConfirmModal from './ConfirmModal/ConfirmModal';
import Todo from './Todo/Todo';
import ContentManagement from './contentmanagement/ContentManagement';
import Tags from './Tags/Tags';
import Subscriptions from './Subscription/Subscriptions';
import Shipping from './Shipping/Shipping';
import Subscription from './Subscription/Subscription';
import MatchingGifts from './MatchingGift/MatchingGifts';
import Transaction from './Transaction/Transaction';
import SubscriptionType from './Subscription/SubscriptionType';
import Search from './Search/Search';
import Stats from './Stats/Stats';
import { Recipients } from './Recipients/Recipients';
import Schools from './Schools/Schools';
import useFavoriteProjects, { FavoriteProjectsContext } from './favorite-projects';
import InvoiceNumberRedirect from './Invoice/InvoiceNumberRedirect';
import { Helmet } from 'react-helmet';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { QueryParamProvider } from 'use-query-params';
import FundAsGift from './Project/FundAsGift';
import Inventory from './Inventory/Inventory';
import useFavoriteOrganizations, { FavoriteOrganizationsContext } from './favorite-organizations';
import VippsPartner from './vipps-partner/VippsPartner';
import Transactions from './Transactions/Transactions';

if (auth.isAuthenticated()) {
    store.dispatch({ type: 'LOGIN_SUCCESSFUL', payload: auth.getProfile() });
} else if (window.location.pathname !== '/callback') {
    auth.login();
}

const handleAuthentication = (nextState) => {
    if (/id_token|error/.test(nextState.location.hash)) {
        auth.handleAuthentication();
    }
};

/** Global refresh av alle datas */

let callbacks = [];

window.subscribe = (callback) => {
    callbacks.push(callback);
};

window.unsubscribe = (callback) => {
    callbacks = callbacks.filter((cb) => cb !== callback);
};

window.notify = () => {
    callbacks.forEach((cb) => cb());
};

export const emailHasSpleisDomain = (user) => {
    const email = user && user.email;
    const emailParts = email && email.split('@');
    return emailParts ? emailParts[emailParts.length - 1].toLowerCase() === 'spleis.no' : false;
};

const AuthenticatedAsSpleisRoute = ({ user, component, ...rest }) => {
    if (emailHasSpleisDomain(user)) {
        return <Route component={component} {...rest} />;
    } else {
        return (
            <Route
                {...rest}
                render={() => {
                    console.log(
                        `Din bruker med e-post ${user?.email || 'INGEN BRUKER'} har ikke tilgang til denne siden`
                    );
                    return <div>Din bruker har ikke tilgang til denne siden</div>;
                }}
            />
        );
    }
};

export const App = connect((state) => ({ user: state.user }))((props) => {
    const favoriteProjects = useFavoriteProjects({ user: props.user });
    const favoriteOrganizations = useFavoriteOrganizations({ user: props.user });
    if (!props.user) {
        return <div>Laster...</div>;
    }
    return (
        <Router history={history}>
            <QueryParamProvider adapter={ReactRouter5Adapter}>
                <Helmet titleTemplate="%s | Spleis Admin" defaultTitle="Spleis Admin" />
                <FavoriteProjectsContext.Provider value={favoriteProjects}>
                    <FavoriteOrganizationsContext.Provider value={favoriteOrganizations}>
                        <div className="main-app">
                            <Route path="/" component={Menu} />
                            <AuthenticatedAsSpleisRoute user={props.user} path="/" component={Todo} exact />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/payouts/:projectId?"
                                component={Payout}
                            />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/payout-files/"
                                component={PayoutFiles}
                                exact
                            />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/payout-files/:id"
                                component={PayoutFile}
                            />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/refunds/:projectId?"
                                component={Refund}
                            />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/controversial/"
                                component={Controversial}
                                exact
                            />
                            <AuthenticatedAsSpleisRoute user={props.user} path="/invoice/" component={Invoices} exact />
                            <AuthenticatedAsSpleisRoute user={props.user} path="/invoice/:id" component={Invoice} />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/invoice-number/:invoiceNumber"
                                component={InvoiceNumberRedirect}
                            />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/invoiceCreate"
                                component={InvoiceCreate}
                                exact
                            />
                            <AuthenticatedAsSpleisRoute user={props.user} path="/tags/:projectId?" component={Tags} />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/projects/:projectId?"
                                component={Projects}
                            />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/project/:projectId"
                                component={ProjectTask}
                            />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/gi-fond/:fundAsGiftCode"
                                component={FundAsGift}
                            />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/transaction/:transactionId"
                                component={Transaction}
                            />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/transactions"
                                component={Transactions}
                            />
                            <AuthenticatedAsSpleisRoute user={props.user} path="/user/:userId" component={User} />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/organizations/:id?"
                                component={Organizations}
                            />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/org/:id"
                                render={(props) => <Redirect to={`/organizations/${props.match.params.id}`} />}
                            />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/organization/:id"
                                render={(props) => <Redirect to={`/organizations/${props.match.params.id}`} />}
                            />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/recipients/:slug?"
                                component={Recipients}
                            />
                            <Route path="/accounting" component={Accounting} />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/cms/:slug?"
                                component={ContentManagement}
                            />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/subscriptions/"
                                component={Subscriptions}
                            />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/subscription/:id"
                                component={Subscription}
                            />
                            <AuthenticatedAsSpleisRoute
                                user={props.user}
                                path="/subscription-type/:name"
                                component={SubscriptionType}
                            />
                            <AuthenticatedAsSpleisRoute user={props.user} path="/shipping/" component={Shipping} />
                            <AuthenticatedAsSpleisRoute user={props.user} path="/inventory/" component={Inventory} />
                            <AuthenticatedAsSpleisRoute user={props.user} path="/mg/:id?" component={MatchingGifts} />
                            <AuthenticatedAsSpleisRoute user={props.user} path="/vipps" component={VippsPartner} />
                            <AuthenticatedAsSpleisRoute user={props.user} path="/search" component={Search} />
                            <AuthenticatedAsSpleisRoute user={props.user} path="/stats/:slug?" component={Stats} />
                            <AuthenticatedAsSpleisRoute user={props.user} path="/schools/:slug?" component={Schools} />
                            <Route
                                path="/callback"
                                render={(props) => {
                                    handleAuthentication(props);
                                    return <div>Callbackuru</div>;
                                }}
                            />
                        </div>
                    </FavoriteOrganizationsContext.Provider>
                </FavoriteProjectsContext.Provider>
            </QueryParamProvider>
        </Router>
    );
});

ReactDOM.render(
    <Provider store={store}>
        <App state={store.getState()} />
    </Provider>,
    document.getElementById('root')
);

document.addEventListener('keydown', (e) => {
    const focus = (elementId) => {
        const el = document.getElementById(elementId);
        el.focus();
        setTimeout(() => el.select && el.select(), 50);
    };
    if (e.ctrlKey && e.key === 'p') {
        focus('project-search-box');
    } else if (e.ctrlKey && e.key === 'u') {
        focus('user-search-box');
    } else if (e.ctrlKey && e.key === 'o') {
        focus('org-search-box');
    }
});

setInterval(() => {
    if (localStorage.getItem('expires_at') && localStorage.getItem('expires_at') - new Date().getTime() < 0) {
        ReactDOM.render(
            <ConfirmModal
                isOpen={true}
                title="Sesjon utløpt"
                message="Du må logge inn på nytt"
                successText="Æsj, ok da"
                cancelText="Næmmen, ok"
                onClickSuccess={() => window.location.reload()}
                onClickCancel={() => window.location.reload()}
            />,
            document.getElementById('root')
        );
    }
}, 1000 * 30);
