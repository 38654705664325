import React from 'react';
import './ShipmentList.scss';
import { spleisUrl } from '../config';
import ShippingListContainer from './ShippingListContainer';
import { Helmet } from 'react-helmet';
import DugnadList from './DugnadList';

const Dugnad = () => (
    <>
        <Helmet>
            <title>VM-Dugnadsprodukter</title>
        </Helmet>
        <ShippingListContainer
            url={`${spleisUrl}/api/admin/transaction/vm-shipping`}
            extraInfo={
                <div>
                    Bestillingsfilen skal lastes opp til{' '}
                    <a
                        href="https://drive.google.com/drive/folders/1lhmUAGiZJvfVE7_J-41vUQwInROR_U5M?usp=sharing"
                        target="_blank"
                    >
                        Google Drive mappen
                    </a>{' '}
                    som er delt med Helthjem.
                </div>
            }
            title="VM-Dugnadsprodukter"
            listComponent={DugnadList}
            showProjectFilter
            showVMProductsFilter
        />
    </>
);

export default Dugnad;
