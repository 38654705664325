import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/nb';
import { Route } from 'react-router-dom';
import api from '../api';
import { spleisUrl } from '../config';
import ButtonLink from '../ButtonLink/ButtonLink';
import TaskList from '../TaskList/TaskList';
import ProjectTask from '../Project/Project';
import TaskListSelect from '../TaskList/TaskListSelect';
import ListOverview from '../ListOverview/ListOverview';
import MainContainer from '../MainContainer/MainContainer';
import { useHistory, useParams } from 'react-router';
import styles from './Projects.module.scss';
import HelmetForSubpages from '../HelmetForSubpages/HelmetForSubpages';
import { SORT_OPTIONS } from '../Project/ProjectSort/ProjectSort';
import { StringParam, useQueryParams } from 'use-query-params';
import { filterProjectsGlobal } from '../ProjectListFilter/ProjectListFilter';

moment.locale('nb');

const displayDateMustBeRefundedAt = (project) => {
    return `Frist: ${moment(project.payout_at || project.published_at)
        .add(9, 'months')
        .fromNow()}`;
};

const FILTER_OPTIONS = [
    { title: 'Nyeste', value: 'latest', defaultSort: 'published-at-desc' },
    { title: 'Til oppfølging (mine)', value: 'my-follow-up', defaultSort: 'published-at-asc' },
    { title: 'Til oppfølging (alle)', value: 'follow-up', defaultSort: 'published-at-asc' },
    { title: 'Prosjekter med gjemt innhold', value: 'content-hidden', defaultSort: 'published-at-asc' },
    { title: 'Prosjekter lagt opp til refusjon', value: 'marked-for-refund', defaultSort: 'marked-for-refund-asc' },
    { title: 'Med selvbetjent refusjon', value: 'with-self-serve-refund', defaultSort: 'published-at-desc' },
    {
        title: 'Må snart refunderes',
        value: 'oldest',
        defaultSort: 'published-at-asc',
        displayDateFn: displayDateMustBeRefundedAt,
    },
    { title: 'Ikke-anerkjente varsel', value: 'unacknowledged', defaultSort: 'published-at-desc' },
    { title: 'Ikke godkjente', value: 'unapproved', defaultSort: 'published-at-asc' },
    { title: 'Ikke godkjente med produkter', value: 'unapproved-with-products', defaultSort: 'published-at-asc' },
    {
        title: 'Godkjent automatisk uten tilbakemelding',
        value: 'auto-approved',
        defaultSort: 'published-at-desc',
        hidden: true,
    },
    { title: 'Alle automatisk godkjente', value: 'all-auto-approved', defaultSort: 'published-at-desc', hidden: true },
    {
        title: 'Kategorisert automatisk uten tilbakemelding',
        value: 'auto-categorized',
        defaultSort: 'published-at-desc',
        hidden: true,
    },
    {
        title: 'Alle automatisk kategoriserte',
        value: 'all-auto-categorized',
        defaultSort: 'published-at-desc',
        hidden: true,
    },
    { title: 'Pauset', value: 'paused', defaultSort: 'published-at-desc' },
    { title: 'Blokkerte (ikke i malen)', value: 'blocked-but-not-by-template', defaultSort: 'published-at-desc' },
    { title: 'Flagget', value: 'observation', defaultSort: 'published-at-desc' },
    { title: 'Ukategoriserte', value: 'uncategorized', defaultSort: 'published-at-desc' },
    { title: 'Favoritter', value: 'favorites', defaultSort: 'version-published-at-desc' },
    {
        title: 'Ikke-refunderbare (privat)',
        value: 'non-refundable-to-private-receivers',
        defaultSort: 'published-at-desc',
        displayDateFn: displayDateMustBeRefundedAt,
    },
    {
        title: 'Ikke-refunderbare (org)',
        value: 'non-refundable-to-organization-receivers',
        defaultSort: 'published-at-desc',
        displayDateFn: displayDateMustBeRefundedAt,
    },
    {
        title: 'Pausede/nedtelling med nye oppdateringer',
        value: 'paused-or-countdown-but-has-updates',
        defaultSort: 'version-published-at-desc',
    },
    { title: 'Varslinger', value: 'unacknowledged-alerts', defaultSort: 'published-at-desc' },
];

const Projects = ({}) => {
    const params = useParams();
    const projectId = parseInt(params.projectId, 10);
    const history = useHistory();
    const [query, setQuery] = useQueryParams({
        sort: StringParam,
        filter: StringParam,
    });
    const filter = query?.filter ? FILTER_OPTIONS.find((f) => f.value === query.filter) : FILTER_OPTIONS[0];
    const sort = query?.sort
        ? SORT_OPTIONS.find((s) => s.value === query.sort)
        : SORT_OPTIONS.find((s) => s.value === filter.defaultSort);
    const [refreshTasks, setRefreshTasks] = useState({});

    const [cachedProjects, setCachedProjects] = useState({});
    const [currentList, setCurrentList] = useState([]);
    const [showOnlyActive, setShowOnlyActive] = useState(false);
    const projectsFilteredByActive = currentList.filter((project) => {
        if (showOnlyActive) {
            return (
                project?.published_at && moment(project.published_at).isBefore() && moment(project.ends_at).isAfter()
            );
        }
        return project;
    });
    const projectsFilteredGlobal = filterProjectsGlobal(projectsFilteredByActive);
    const clearProjectCache = useCallback(() => {
        setCachedProjects({});
    }, [setCachedProjects]);
    const refreshProjectTasks = useCallback(() => {
        setTimeout(() => setRefreshTasks({}), 250);
    }, []);

    useEffect(() => {
        window.subscribe(clearProjectCache);
        window.subscribe(refreshProjectTasks);

        return () => {
            window.unsubscribe(clearProjectCache);
            window.unsubscribe(refreshProjectTasks);
        };
    }, []);

    const onChangeFilter = (filterValue) => {
        const filterOption = FILTER_OPTIONS.find((f) => f.value === filterValue);
        const sortOption = SORT_OPTIONS.find((s) => s.value === filterOption.defaultSort);
        setQuery({
            filter: filterOption.value,
            sort: sortOption.value,
        });
    };

    const onChangeSort = (sortValue) => {
        setQuery({
            sort: sortValue,
        });
    };

    useEffect(() => {
        fetchProjectsByFilter(filter).then((projects) => {
            const sortedProjects = sort.fn(projects);
            setCurrentList(sortedProjects || []);
            setCachedProjects({
                ...cachedProjects,
                [filter.value]: projects,
            });
        });
    }, [filter, sort, refreshTasks]);

    const fetchProjectsByFilter = async (filter) => {
        try {
            if (!filter?.value) {
                return false;
            }

            if (cachedProjects[filter.value]) {
                return cachedProjects[filter.value];
            }

            return await api.get(`${spleisUrl}/api/admin/project/${filter.value}`);
        } catch (e) {
            return [];
        }
    };

    return (
        <MainContainer>
            <HelmetForSubpages title="Prosjekter" />
            {!!projectId && (
                <ButtonLink className="back-link" onClick={history.goBack}>
                    &larr; Tilbake
                </ButtonLink>
            )}

            <div className={`task-list-container ${!projectId ? 'block' : ''}`}>
                <div className="task-list-wrapper">
                    <header className="tasks-header">
                        <TaskListSelect
                            options={FILTER_OPTIONS}
                            onChange={(e) => onChangeFilter(e.target.value)}
                            selected={filter}
                        />
                        <TaskListSelect
                            options={SORT_OPTIONS}
                            onChange={(e) => onChangeSort(e.target.value)}
                            selected={sort}
                        />
                        <div className="tasks-header-subtitle">{projectsFilteredGlobal.length} innsamlinger</div>

                        <ButtonLink onClick={() => setShowOnlyActive(!showOnlyActive)} className={styles.button}>
                            {showOnlyActive ? `Vis alle (${currentList.length})` : `Skjul ikke-aktive`}
                        </ButtonLink>
                    </header>
                    <ul className="task-list">
                        {projectsFilteredGlobal?.map((p) => (
                            <TaskList
                                project={p}
                                key={p.id}
                                link={`/projects/${p.id}?filter=${filter.value}`}
                                selected={projectId === parseInt(p.id, 0)}
                                displayDateFn={filter?.displayDateFn}
                            />
                        ))}
                    </ul>
                </div>
            </div>

            <Route path="/projects/:projectId" component={ProjectTask} />
            {!projectId && (
                <ListOverview
                    type={'prosjekter'}
                    items={FILTER_OPTIONS.filter((filter) => !filter.hidden).map((filterOption) => {
                        return {
                            title: filterOption.title,
                            callback: () => onChangeFilter(filterOption.value),
                            fetchItems: () => fetchProjectsByFilter(filterOption),
                        };
                    })}
                />
            )}
        </MainContainer>
    );
};

export default Projects;
