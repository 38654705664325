import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import Async from 'react-select/async';
import classnames from 'classnames';
import { auth } from '../auth';
import store, { showError } from '../store';
import Button from '../Button/Button';
import ButtonLink from '../ButtonLink/ButtonLink';
import api from '../api';
import { spleisUrl } from '../config';
import styles from './Menu.module.scss';
import ProjectListFilter from '../ProjectListFilter/ProjectListFilter';

export class Search extends Component {
    state = {
        results: [],
    };

    debounceSearch = _.debounce(
        (opts, callback) => {
            this.search(opts, callback);
        },
        500,
        { maxWait: 1000 }
    );

    search = async (opts, callback) => {
        if (opts.trim().length === 0) {
            return callback(this.state.results);
        } else {
            return api
                .get(`${this.props.searchUrl}?q=${opts.toLowerCase()}`)
                .then((res) => {
                    const mappedResults = res.map((resultObject) => ({
                        value: resultObject.id,
                        object: resultObject,
                    }));
                    this.setState({ results: mappedResults });

                    callback(mappedResults);
                })
                .catch((err) => showError('Feil ved søk: ' + err));
        }
    };

    renderOption = ({ innerProps, innerRef, data, isSelected, isFocused }) => {
        return (
            <div
                {...innerProps}
                ref={innerRef}
                key={data.value}
                className={classnames(styles.option, isFocused && styles.isFocused, isSelected && styles.isSelected)}
            >
                {this.props.listItem(data)}
            </div>
        );
    };

    renderSelectedOption = ({ innerProps, innerRef, data, isSelected, isFocused, ...rest }) => {
        return (
            <div {...innerProps} ref={innerRef} key={data.value} className={styles.selectedOption}>
                {this.props.selectedItem(data)}
            </div>
        );
    };

    render() {
        return (
            <Async
                id={this.props.id}
                backspaceRemovesValue={false}
                loadOptions={this.debounceSearch}
                onChange={(option) => {
                    this.props.onClick(option.value);
                }}
                options={this.state.results}
                placeholder={this.props.placeholder}
                className={[this.props.className, styles.searchBox].join(' ')}
                components={{ Option: this.renderOption, SingleValue: this.renderSelectedOption }}
            />
        );
    }
}

const MenuNavigationItem = ({ to, children, linkClassName }) => {
    return (
        <li className={styles['menu-navigation-item']}>
            <Link className={`${styles['menu-navigation-item-link']} ${linkClassName || ''}`} to={to}>
                {children}
            </Link>
        </li>
    );
};

const MenuNavigationMenu = (props) => {
    const [mouseOver, setMouseOver] = useState(false);

    return (
        <li
            className={styles['menu-navigation-item']}
            onMouseOver={() => setMouseOver(true)}
            onMouseOut={() => setMouseOver(false)}
        >
            <span className={styles[`menu-navigation-item-link`]}>{props.label}</span>
            <ul className={classnames(styles['menu-overlay'], mouseOver && styles['menu-overlay-show'])}>
                {props.children}
            </ul>
        </li>
    );
};

const MenuSearch = (props) => (
    <div className={styles['menu-search-form-container']}>
        <form
            onSubmit={(e) => {
                e.preventDefault();
                const element = document.getElementById('project-search-box');
                if (Number.isInteger(Number(element.value))) {
                    props.goTo(`/project/${element.value}`);
                } else {
                    props.goTo(`/gi-fond/${element.value}`);
                }
            }}
        >
            <input className={styles['menu-search-input']} placeholder="Ctrl+P" id="project-search-box" />
            <Button>Finn spleis</Button>
        </form>
        <Search
            id="spleis-search-box"
            searchUrl={`${spleisUrl}/api/admin/search`}
            placeholder="Finn spleis"
            onClick={(id) => props.goTo(`/project/${id}`)}
            selectedItem={(option) => <div>{option.object.title ? option.object.title : 'Ukjent'}</div>}
            listItem={(option) => (
                <div>
                    <div>{option.object.title ? option.object.title : 'Ukjent'}</div>
                    <div className={styles.projectOwner}>
                        - {option.object.owner_first + ' ' + option.object.owner_last}
                    </div>
                </div>
            )}
        />
        <Search
            id="user-search-box"
            searchUrl={`${spleisUrl}/api/admin/user/search`}
            placeholder="Finn bruker (ctrl+u)"
            onClick={(id) => props.goTo(`/user/${id}`)}
            selectedItem={(option) => (
                <div>{option.object.first ? option.object.first + ' ' + option.object.last : 'Soft user'}</div>
            )}
            listItem={(option) => (
                <div>
                    <div>{option.object.first ? option.object.first + ' ' + option.object.last : 'Soft user'}</div>
                    <div>{option.object.email}</div>
                </div>
            )}
        />
        <Search
            id="org-search-box"
            searchUrl={`${spleisUrl}/api/admin/organization/search`}
            placeholder="Finn org (ctrl+o)"
            onClick={(id) => props.goTo(`/organizations/${id}`)}
            selectedItem={(option) => <div>{option.object.name ? option.object.name : 'Ukjent'}</div>}
            listItem={(option) => (
                <div>
                    <div>{option.object.name ? option.object.name : 'Ukjent'}</div>
                    <div>{option.object.organization_number}</div>
                </div>
            )}
        />
        <ProjectListFilter />
    </div>
);

const ProjectAlertsBadge = () => {
    const [projectsWithAlerts, setProjectsWithAlerts] = useState([]);

    const fetchAlerts = () => {
        api.get(`${spleisUrl}/api/admin/project/unacknowledged-alerts`).then((res) => setProjectsWithAlerts(res));
    };

    useEffect(() => {
        fetchAlerts();
    }, []);

    useEffect(() => {
        window.subscribe(fetchAlerts);

        return () => {
            window.unsubscribe(fetchAlerts);
        };
    }, []);

    useEffect(() => {
        const id = setInterval(() => {
            fetchAlerts();
        }, 60000);

        return () => clearInterval(id);
    }, []);

    if (!projectsWithAlerts?.length) {
        return null;
    }

    return (
        <li className={styles['menu-navigation-item']}>
            <Link
                className={styles[`menu-navigation-item-badge`]}
                to={`/projects/${projectsWithAlerts[0].id}?filter=unacknowledged-alerts`}
            >
                {projectsWithAlerts.length} {projectsWithAlerts.length > 1 ? 'varsler' : 'varsel'}
            </Link>
        </li>
    );
};

class Menu extends Component {
    state = { hidingAlert: false };

    goTo(route) {
        this.props.history.push(route);
    }

    isSelected(page) {
        return this.props.location.pathname.startsWith(page);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.alert && !nextProps.alert) {
            this.setState({ hidingAlert: true });
            setTimeout(() => this.setState({ hidingAlert: false }), 500);
        }
    }

    render() {
        if (!auth.isAuthenticated()) {
            return null;
        }
        return (
            <div className={styles['menu-main-container']}>
                <div className={styles['menu-container']}>
                    <Link className={styles['menu-logo-container']} to="/">
                        <img
                            className={styles['menu-logo']}
                            src="https://www.spleis.no/public/images/spleis.svg"
                            alt="logo"
                        />
                    </Link>
                    <nav>
                        <ul className={styles['menu-navigation']}>
                            <ProjectAlertsBadge />
                            <MenuNavigationItem to="/" selected={this.props.location.pathname === '/'}>
                                Todo
                            </MenuNavigationItem>
                            <MenuNavigationMenu label={'Operations'}>
                                <MenuNavigationItem to="/invoice/" selected={this.isSelected('/invoice/')}>
                                    Faktura
                                </MenuNavigationItem>
                                <MenuNavigationItem to="/refunds/" selected={this.isSelected('/refunds/')}>
                                    Refundering
                                </MenuNavigationItem>
                                <MenuNavigationItem to="/controversial/" selected={this.isSelected('/controversial/')}>
                                    Kontroversielle spleiser
                                </MenuNavigationItem>
                            </MenuNavigationMenu>
                            <MenuNavigationMenu label={'Utbetaling'}>
                                <MenuNavigationItem
                                    to="/payouts/"
                                    selected={this.props.location.pathname === '/payouts/'}
                                >
                                    Utbetaling
                                </MenuNavigationItem>
                                <MenuNavigationItem to="/payout-files/" selected={this.isSelected('/payout-files')}>
                                    Betalingsfiler
                                </MenuNavigationItem>
                            </MenuNavigationMenu>
                            <MenuNavigationItem to="/projects/" selected={this.isSelected('/projects/')}>
                                Prosjekt
                            </MenuNavigationItem>
                            <MenuNavigationItem to="/transactions/" selected={this.isSelected('/transactions/')}>
                                Transaksjoner
                            </MenuNavigationItem>
                            <MenuNavigationItem to="/organizations/" selected={this.isSelected('/organizations/')}>
                                Organisasjon
                            </MenuNavigationItem>
                            <MenuNavigationItem
                                to="/recipients/missing-info-org"
                                selected={this.isSelected('/recipients/missing-info-org')}
                            >
                                Mottakere
                            </MenuNavigationItem>
                            <MenuNavigationMenu label={'Produkter'}>
                                <MenuNavigationItem to="/inventory/">Varetelling</MenuNavigationItem>
                                <MenuNavigationItem to="/shipping/">Utsendelse produkter</MenuNavigationItem>
                                <MenuNavigationItem to="/subscriptions/">Abonnement</MenuNavigationItem>
                                <MenuNavigationItem to="/mg/">Matching gift</MenuNavigationItem>
                                <MenuNavigationItem to="/vipps/">Vipps partner</MenuNavigationItem>
                            </MenuNavigationMenu>

                            <MenuNavigationItem to="/accounting/" selected={this.isSelected('/accounting/')}>
                                Regnskap
                            </MenuNavigationItem>
                            <MenuNavigationItem to="/cms/" selected={this.isSelected('/cms/')}>
                                CMS
                            </MenuNavigationItem>

                            <MenuNavigationItem to="/schools/" selected={this.isSelected('/schools/')}>
                                Skoler
                            </MenuNavigationItem>

                            <MenuNavigationItem to="/stats/" selected={this.isSelected('/stats/')}>
                                Statistikk
                            </MenuNavigationItem>
                        </ul>
                    </nav>
                    <MenuSearch goTo={this.goTo.bind(this)} />
                </div>
                {(this.props.alert || this.state.hidingAlert) && (
                    <div
                        className={classnames(styles.menuAlert, {
                            hidingAlert: this.state.hidingAlert,
                            'alert-danger': this.props.alert && this.props.alert.type === 'error',
                            'alert-success': this.props.alert && this.props.alert.type !== 'error',
                        })}
                    >
                        <ButtonLink className="close" onClick={() => store.dispatch({ type: 'ALERT_CLEAR' })}>
                            &times;
                        </ButtonLink>
                        {this.props.alert && this.props.alert.text}
                    </div>
                )}
            </div>
        );
    }
}

export default connect((state) => ({ user: state.user, alert: state.alert }))(Menu);
