import React, {useEffect, useState} from 'react';
import api from '../api';
import {showError, showInfo} from '../store';
import Button from '../Button/Button';
import './ShipmentList.scss';
import SelectPrizesAndDownloadCsv from './SelectPrizesAndDownloadCsv';

const ShippingListContainer = ({
                                   url,
                                   listComponent,
                                   title,
                                   prizes,
                                   extraInfo,
                                   showProjectFilter,
                                   showVMProductsFilter
                               }) => {
    const [shippingProducts, setShippingProducts] = useState([]);
    const [shippingProductsNotApproved, setShippingProductsNotApproved] = useState([]);
    const [projectIdFilter, setProjectIdFilter] = useState();
    const [onlyPannebandFilter, setOnlyPannebandFilter] = useState(false);
    const [onlyLueFilter, setOnlyLueFilter] = useState(false);

    useEffect(() => {
        fetchData();
    }, [onlyPannebandFilter, onlyLueFilter]);

    const fetchData = () => {
        const queryParams = new URLSearchParams({
            ...(projectIdFilter && {project_id: projectIdFilter}),
            ...(onlyPannebandFilter && {only_panneband: true}),
            ...(onlyLueFilter && {only_lue: true}),
        });
        const queryParamsString = '?' + queryParams.toString();
        api.get(url + queryParamsString)
            .then((res) => setShippingProducts(res))
            .catch(() => showError('Feil ved henting av produkter'));
        api.get(url + '/not-approved' + queryParamsString)
            .then((res) => setShippingProductsNotApproved(res))
            .catch(() => showError('Feil ved henting av produkter'));
    };

    const markAsSent = () => {
        api.put(`${url}/set-downloaded-sent`)
            .then((blob) => {
                window.notify();
                showInfo('Sendingsstatus er oppdatert');
            })
            .catch((err) => showError('Noe feilet da vi skulle sendingsstatus' + err));
    };

    useEffect(() => {
        fetchData();
        window.subscribe(fetchData);
    }, []);

    const ShippingList = listComponent;
    return (
        <div className="promoted-container">
            <header className="task-header">
                <h2 className="task-title">{title}</h2>
            </header>
            <div className="promoted-content">
                <SelectPrizesAndDownloadCsv url={url} prizes={prizes} title={title} projectId={projectIdFilter}
                                            onlyPanneband={onlyPannebandFilter} onlyLue={onlyLueFilter}/>
                <Button onClick={markAsSent}>Marker nedlastede som sendt</Button>
            </div>
            {extraInfo && <div className="promoted-content">{extraInfo}</div>}
            {showProjectFilter && (
                <label>
                    Prosjekt
                    <input
                        name={'project'}
                        value={projectIdFilter}
                        onChange={(e) => {
                            setProjectIdFilter(e.target.value);
                        }}
                        onKeyUp={e => {
                            if (e.key === 'Enter') {
                                fetchData();
                            }
                        }}
                    />
                </label>
            )}
            {showVMProductsFilter && (
                <>
                    <label>
                        <input
                            type="checkbox"
                            checked={onlyPannebandFilter}
                            onChange={() => {
                                setOnlyPannebandFilter(!onlyPannebandFilter);
                            }}
                        />
                        Bare Pannebånd kjøpt
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={onlyLueFilter}
                            onChange={() => {
                                setOnlyLueFilter(!onlyLueFilter);
                            }}
                        />
                        Bare Lue kjøpt
                    </label>
                </>
            )}
            <div className="promoted-content">
                <ShippingList
                    title="Ikke sendt"
                    shippingProducts={shippingProducts?.filter((p) => !(p.sent_at || p.reward_sent_at))}
                />
                <ShippingList
                    title="Ikke godkjent enda"
                    shippingProducts={shippingProductsNotApproved}
                    disableActions
                />
                <ShippingList
                    title="Sendt"
                    shippingProducts={shippingProducts?.filter((p) => p.sent_at || p.reward_sent_at)}
                />
            </div>
        </div>
    );
};

export default ShippingListContainer;
