import React, { useEffect, useState } from 'react';
import TaskContainer from '../TaskContainer/TaskContainer';
import { spleisUrl } from '../config';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import api from '../api';
import { VERIFICATION_STATUSES } from '../Org/Org';
import styles from '../Menu/Menu.module.scss';
import { auth } from '../auth';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import ButtonLink from '../ButtonLink/ButtonLink';
import useLocalStorage from '../localstorage';
import TodoConfig from './TodoConfig';
import { filterProjectsGlobal } from '../ProjectListFilter/ProjectListFilter';

const QUERY_PATHS = {
    UNAPPROVED: `${spleisUrl}/api/admin/project/unapproved`,
    UNAPPROVED_WITH_PRODUCTS: `${spleisUrl}/api/admin/project/unapproved-with-products`,
    MY_FOLLOW_UP: `${spleisUrl}/api/admin/project/my-follow-up`,
    FOLLOW_UP: `${spleisUrl}/api/admin/project/follow-up`,
    CONTENT_HIDDEN: `${spleisUrl}/api/admin/project/content-hidden`,
    INVOICE: `${spleisUrl}/api/admin/invoice`,
    FAILED_NOT_REFUNDED: `${spleisUrl}/api/admin/project/failed-not-refunded`,
    DELETED_NOT_REFUNDED: `${spleisUrl}/api/admin/project/deleted-not-refunded`,
    PAUSED_OR_COUNTDOWN_BUT_HAS_UPDATES: `${spleisUrl}/api/admin/project/paused-or-countdown-but-has-updates`,
    OLDEST: `${spleisUrl}/api/admin/project/oldest`,
    UNACKNOWLEDGED: `${spleisUrl}/api/admin/project/unacknowledged`,
    CONTACT_CANDIDATES: `${spleisUrl}/api/admin/organization/contact-candidates`,
    ACTIVE_WITHOUT_CONTACT_PERSON: `${spleisUrl}/api/admin/organization/active-without-contact-person`,
    PAUSED: `${spleisUrl}/api/admin/project/paused`,
    PAYOUT_TODOS: `${spleisUrl}/api/admin/payout/todos/`,
    UNCATEGORIZED: `${spleisUrl}/api/admin/project/uncategorized`,
    MATCHING_GIFT_LIST: `${spleisUrl}/api/admin/project/matching-gift/list`,
    FAVORITE_PROJECTS: `${spleisUrl}/api/admin/project/favorites`,
    FAVORITE_ORGANIZATIONS: `${spleisUrl}/api/admin/organization/favorites`,
    NOT_REFUNDABLE_PRIVATE_RECEIVERS: `${spleisUrl}/api/admin/project/non-refundable-to-private-receivers`,
    NOT_REFUNDABLE_ORG_RECEIVERS: `${spleisUrl}/api/admin/project/non-refundable-to-organization-receivers`,
};

const getIdOfFirstElementOrNothing = (data, idField) => {
    const id = data?.[0]?.[idField] || data?.[0]?.id;
    return id || '';
};

export const TODO_DEFINITIONS = [
    {
        default: true,
        id: 'FAVORITE_PROJECTS',
        label: 'favorittprosjekter ⭐',
        path: QUERY_PATHS.FAVORITE_PROJECTS,
        link: (projects) => `/projects/${getIdOfFirstElementOrNothing(projects)}?filter=favorites`,
        colorize: false,
        filterFn: filterProjectsGlobal,
    },
    {
        default: true,
        id: 'FAVORITE_ORGANIZATIONS',
        label: 'favorittorganisasjoner ⭐',
        path: QUERY_PATHS.FAVORITE_ORGANIZATIONS,
        link: (orgs) => `/organizations/${getIdOfFirstElementOrNothing(orgs)}?filter=FAVORITES`,
        colorize: false,
    },
    {
        id: 'PAUSED_OR_COUNTDOWN_BUT_HAS_UPDATES',
        label: 'pausede/nedtelling med nye oppdateringer',
        path: QUERY_PATHS.PAUSED_OR_COUNTDOWN_BUT_HAS_UPDATES,
        link: (projects) =>
            `/projects/${getIdOfFirstElementOrNothing(projects)}?filter=paused-or-countdown-but-has-updates`,
        limit: 10,
        filterFn: filterProjectsGlobal,
    },
    {
        id: 'PAUSED',
        label: 'pausede prosjekter',
        path: QUERY_PATHS.PAUSED,
        link: (projects) => `/projects/${getIdOfFirstElementOrNothing(projects)}?filter=paused`,
        limit: 10,
        filterFn: filterProjectsGlobal,
    },
    {
        id: 'UNAPPROVED',
        label: 'ikke-godkjente prosjekter',
        path: QUERY_PATHS.UNAPPROVED,
        link: (projects) => `/projects/${getIdOfFirstElementOrNothing(projects)}?filter=unapproved`,
        limit: 10,
        filterFn: filterProjectsGlobal,
    },
    {
        id: 'UNAPPROVED_WITH_PRODUCTS',
        label: 'ikke-godkjente prosjekter med produkter',
        path: QUERY_PATHS.UNAPPROVED_WITH_PRODUCTS,
        link: (projects) => `/projects/${getIdOfFirstElementOrNothing(projects)}?filter=unapproved-with-products`,
        limit: 10,
        filterFn: filterProjectsGlobal,
    },
    {
        id: 'MY_FOLLOW_UP',
        label: 'prosjekter til oppfølging (mine)',
        path: QUERY_PATHS.MY_FOLLOW_UP,
        link: (projects) => `/projects/${getIdOfFirstElementOrNothing(projects)}?filter=my-follow-up`,
        limit: 10,
        filterFn: filterProjectsGlobal,
    },
    {
        id: 'FOLLOW_UP',
        label: 'prosjekter til oppfølging (alle)',
        path: QUERY_PATHS.FOLLOW_UP,
        link: (projects) => `/projects/${getIdOfFirstElementOrNothing(projects)}?filter=follow-up`,
        limit: 10,
        filterFn: filterProjectsGlobal,
    },
    {
        id: 'CONTENT_HIDDEN',
        label: 'prosjekter med gjemt innhold',
        path: QUERY_PATHS.CONTENT_HIDDEN,
        link: (projects) => `/projects/${getIdOfFirstElementOrNothing(projects)}?filter=content-hidden`,
        limit: 10,
        filterFn: filterProjectsGlobal,
    },
    {
        id: 'UNCATEGORIZED',
        label: 'ukategoriserte prosjekter',
        path: QUERY_PATHS.UNCATEGORIZED,
        link: (projects) => `/projects/${getIdOfFirstElementOrNothing(projects)}?filter=uncategorized`,
        limit: 30,
        filterFn: filterProjectsGlobal,
    },
    {
        id: 'FAILED_NOT_REFUNDED',
        label: 'utestående refunds for ikke-vellykkede prosjekter',
        path: QUERY_PATHS.FAILED_NOT_REFUNDED,
        link: (projects) => `/refunds/${getIdOfFirstElementOrNothing(projects)}?filter=failed-not-refunded`,
        limit: 5,
        filterFn: filterProjectsGlobal,
    },
    {
        id: 'DELETED_NOT_REFUNDED',
        label: 'utestående refunds for slettede prosjekter',
        path: QUERY_PATHS.DELETED_NOT_REFUNDED,
        link: (projects) => `/refunds/${getIdOfFirstElementOrNothing(projects)}?filter=deleted-not-refunded`,
        limit: 5,
        filterFn: filterProjectsGlobal,
    },
    {
        id: 'OLDEST',
        label: 'prosjekter som må refunderes snart',
        path: QUERY_PATHS.OLDEST,
        link: (projects) => `/projects/${getIdOfFirstElementOrNothing(projects)}?filter=oldest`,
        limit: 5,
        filterFn: filterProjectsGlobal,
    },
    {
        id: 'INVOICE',
        label: 'nye fakturaer',
        path: QUERY_PATHS.INVOICE,
        link: '/invoice/',
        limit: 5,
        filterFn: (res) => res.db.filter((invoice) => !invoice.sent_at && !invoice.creditnote_at),
    },
    {
        id: 'MATCHING_GIFT_LIST',
        label: 'matching gifts der fakturaer kan sendes',
        path: QUERY_PATHS.MATCHING_GIFT_LIST,
        link: '/mg',
        limit: 3,
        filterFn: (res) =>
            res.filter(
                (mg) =>
                    !mg.invoice &&
                    mg.collected_amount > 0 &&
                    (mg.collected_amount > mg.max_amount || mg.project_ended) &&
                    !(mg.transaction_id || mg.profile?.paidAmount)
            ),
    },
    {
        id: 'UNACKNOWLEDGED',
        label: 'ikke-anerkjente rapporteringer',
        path: QUERY_PATHS.UNACKNOWLEDGED,
        link: (projects) => `/projects/${getIdOfFirstElementOrNothing(projects)}?filter=unacknowledged`,
        limit: 10,
        filterFn: filterProjectsGlobal,
    },
    {
        id: 'READY_FOR_PAYOUT_PRIVATE',
        label: 'utbetalinger til private',
        path: QUERY_PATHS.PAYOUT_TODOS,
        link: (projects) => `/payouts/${getIdOfFirstElementOrNothing(projects)}?filter=READY_FOR_PAYOUT_PRIVATE`,
        limit: 10,
        filterFn: (res) =>
            filterProjectsGlobal(res.filter((todo) => todo.statuses?.includes('READY_FOR_PAYOUT_PRIVATE'))),
    },
    {
        id: 'READY_FOR_PAYOUT_ORGANIZATION',
        label: 'utbetalinger til organisasjoner',
        path: QUERY_PATHS.PAYOUT_TODOS,
        link: (projects) => `/payouts/${getIdOfFirstElementOrNothing(projects)}?filter=READY_FOR_PAYOUT_ORGANIZATION`,
        limit: 10,
        filterFn: (res) =>
            filterProjectsGlobal(res.filter((todo) => todo.statuses?.includes('READY_FOR_PAYOUT_ORGANIZATION'))),
    },
    {
        id: 'ORGANIZATION_NOT_VERIFIED',
        label: 'ikke-verifiserte organisasjoner',
        path: QUERY_PATHS.PAYOUT_TODOS,
        link: (projects) => `/payouts/${getIdOfFirstElementOrNothing(projects)}?filter=ORGANIZATION_NOT_VERIFIED`,
        limit: 3,
        filterFn: (res) =>
            filterProjectsGlobal(res.filter((todo) => todo.statuses?.includes('ORGANIZATION_NOT_VERIFIED'))),
    },
    {
        id: 'PROJECT_BLOCKED_FOR_PAYOUT',
        label: 'prosjekter er blokkert for utbetaling',
        path: QUERY_PATHS.PAYOUT_TODOS,
        link: (projects) => `/payouts/${getIdOfFirstElementOrNothing(projects)}?filter=PROJECT_BLOCKED_FOR_PAYOUT`,
        limit: 20,
        filterFn: (res) =>
            filterProjectsGlobal(res.filter((todo) => todo.statuses?.includes('PROJECT_BLOCKED_FOR_PAYOUT'))),
    },
    {
        id: 'CONTACT_CANDIDATES',
        label: 'organisasjon(er) har foreslåtte kontaktpersoner som ikke er godkjent',
        path: QUERY_PATHS.CONTACT_CANDIDATES,
        link: (orgs) =>
            `/organizations/${getIdOfFirstElementOrNothing(orgs, 'org_id')}?filter=NOT_APPROVED_MAIN_CONTACT_CANDIDATE`,
        limit: 10,
        filterFn: (res) => {
            const contactCandidates = res.filter(
                (organization) => organization.verification_status !== VERIFICATION_STATUSES.BLOCKED_ARCHIVED
            );
            return _.uniqBy(contactCandidates || [], 'org_id');
        },
    },
    {
        id: 'ACTIVE_ORGANIZATION_WITHOUT_CONTACT',
        label: 'organisasjon(er) har aktive innsamlinger men mangler kontaktperson',
        path: QUERY_PATHS.ACTIVE_WITHOUT_CONTACT_PERSON,
        link: (orgs) =>
            `/organizations/${getIdOfFirstElementOrNothing(orgs, 'org_id')}?filter=HAS_PROJECT_MISSING_MAIN_CONTACT`,
        limit: 10,
    },
    {
        id: 'NOT_REFUNDABLE_PRIVATE_RECEIVERS',
        label: 'ikke-refunderbare prosjekter til private mottakere',
        path: QUERY_PATHS.NOT_REFUNDABLE_PRIVATE_RECEIVERS,
        link: (projects) =>
            `/projects/${getIdOfFirstElementOrNothing(projects)}?filter=non-refundable-to-private-receivers`,
        limit: 9999,
        filterFn: filterProjectsGlobal,
    },
    {
        id: 'NOT_REFUNDABLE_ORG_RECEIVERS',
        label: 'ikke-refunderbare prosjekter til organisasjoner',
        path: QUERY_PATHS.NOT_REFUNDABLE_ORG_RECEIVERS,
        link: (projects) =>
            `/projects/${getIdOfFirstElementOrNothing(projects)}?filter=non-refundable-to-organization-receivers`,
        limit: 9999,
        filterFn: filterProjectsGlobal,
    },
];

const DEFAULT_ACTIVE_TODO_DEFINITION_IDS = TODO_DEFINITIONS.filter((todoDefinition) => todoDefinition.default).map(
    (todoDefinition) => todoDefinition.id
);

const TodoItem = ({ label, data = 0, link, limit, colorize = true, loading }) => {
    if (loading) {
        return <div style={{ marginBottom: '20px', color: 'darkgray' }}>Henter {label}...</div>;
    }
    const color = data === 0 || !colorize ? 'green' : data < limit ? 'orange' : 'red';
    return (
        <div style={{ marginBottom: '20px' }}>
            <Link to={link} style={{ color }}>
                {data} {label}
            </Link>
        </div>
    );
};

const Todo = () => {
    const [todoConfig, setTodoConfig] = useLocalStorage('TODO_CONFIG', {
        initialized: false,
        configs: [...DEFAULT_ACTIVE_TODO_DEFINITION_IDS],
    });
    const activeTodoDefinitions = TODO_DEFINITIONS.filter((def) => todoConfig.configs.includes(def.id));
    const activeQueryPaths = Object.entries(QUERY_PATHS).filter(([id, path]) =>
        activeTodoDefinitions.find((def) => def.path === path)
    );
    const [queryResults, setQueryResults] = useState({});

    useEffect(() => {
        activeQueryPaths.forEach((queryPath) => {
            if (!queryResults[queryPath[0]]) {
                api.get(queryPath[1]).then((res) => {
                    setQueryResults((previousState) => ({
                        ...previousState,
                        [queryPath[0]]: res,
                    }));
                });
            }
        });
    }, [todoConfig]);

    const [showLogoutModal, setShowLogoutModal] = useState(null);

    return (
        <TaskContainer>
            <header className="task-header">
                <h1 className="task-title">Daily ops</h1>
            </header>
            <div className={styles.loggedInAs}>
                Du er logget inn som {auth.getProfile().given_name}
                <ButtonLink onClick={() => setShowLogoutModal(true)}>Logg ut</ButtonLink>
                <ConfirmModal
                    isOpen={showLogoutModal}
                    message="Vil du logge ut?"
                    cancelText={'Avbryt'}
                    successText={'Logg ut'}
                    onClickCancel={() => setShowLogoutModal(false)}
                    onClickSuccess={auth.logout}
                />
            </div>

            <div className="task-body">
                {activeTodoDefinitions.map((todoDefinition) => {
                    const queryPathId = activeQueryPaths.find((queryPath) => queryPath[1] === todoDefinition.path)[0];
                    const data = queryResults[queryPathId];
                    const filteredData = data ? todoDefinition.filterFn?.(data) || data : data;
                    const link =
                        typeof todoDefinition.link === 'function'
                            ? todoDefinition.link?.(filteredData)
                            : todoDefinition.link;
                    return (
                        <TodoItem
                            label={todoDefinition.label}
                            link={link}
                            loading={!data}
                            data={filteredData?.length}
                            colorize={todoDefinition.colorize}
                            limit={todoDefinition.limit}
                        />
                    );
                })}
            </div>

            <TodoConfig todoConfig={todoConfig} setTodoConfig={setTodoConfig} />
        </TaskContainer>
    );
};

export default Todo;
