import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../api';
import { spleisUrl } from '../config';
import { showError } from '../store';
import InvoiceList from './InvoiceList';

const InvoiceActions = () => {
    const [q, setQ] = useState('');
    const [invoices, setInvoices] = useState([]);

    const doSearch = () => {
        api.get(`${spleisUrl}/api/admin/invoice/search?q=${encodeURI(q)}`)
            .then((res) => setInvoices(res.invoices))
            .catch(() => showError('Feil ved søking etter fakturaer'));
    };

    return (
        <>
            <div>
                Søk:
                {' '}
                <input
                    type="text"
                    value={q || ''}
                    onKeyDown={(e) => e.key === 'Enter' && doSearch()}
                    onChange={(e) => setQ(e.target.value)}
                />
                {' '}
                <Link to="/invoiceCreate">Opprett ny</Link>
            </div>
            {invoices?.length > 0 && <div className="task-body">
                <InvoiceList title="Søkeresultat" invoices={invoices} />
            </div>}
        </>
    );
};

export default InvoiceActions;
