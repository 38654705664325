import React, { Component, useEffect, useState } from 'react';
import _ from 'lodash';
import { spleisFrontUrl, spleisUrl } from '../config';
import TaskContainer from '../TaskContainer/TaskContainer';
import OrganizationInfo from './OrganizationInfo';
import api from '../api';
import { showError, showInfo } from '../store';
import './Org.scss';
import BankAccount from './BankAccount';
import Comments from '../Comments/Comments';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import Button from '../Button/Button';
import ContactCandidates from './ContactCandidates';
import { ProjectList } from '../Project/ProjectList';
import InvoiceTable from '../Invoice/InvoiceTable';
import { Helmet } from 'react-helmet';
import { MainContacts } from './MainContacts';
import { ChangeAccountNumberButton } from './ChangeAccountNumberButton';
import { Subscription } from './Subscription';
import { NifContacts } from './NifContacts';
import { NifBankAccountNumber } from './NifBankAccountNumber';
import AddOrEditContactPersonButton from './AddOrEditContactPersonButton/AddOrEditContactPersonButton';
import Banner from '../Banner/Banner';

export const VERIFICATION_STATUSES = {
    TODO: 'Klar for verifisering',
    IN_WORK: 'I arbeid',
    BLOCKED_WAITING_FOR_RESPONSE: 'Venter på svar',
    BLOCKED_ARCHIVED: 'Arkivert',
    VERIFIED: 'Verifisert',
};

const Org = ({
    match,
    organization,
    projects = [],
    projectLimit,
    increaseProjectLimit,
    bankAccount,
    nifBankAccount,
    refreshData,
    contactUsers = [],
    contactCandidates,
    nifContacts = [],
}) => {
    const [invoices, setInvoices] = useState([]);
    const [creditedInvoices, setCreditedInvoices] = useState([]);
    const organizationNumber = organization?.organization_number;
    const [yellowMark, setYellowMark] = useState(null);

    useEffect(() => {
        if (organization?.id) {
            api.get(`${spleisUrl}/api/admin/organization/${organization?.id}/tagged-project-ids?tag=kundekontroll`)
                .then((res) => {
                    if (res.projectIds?.length > 0) {
                        setYellowMark('Kundekontroll')
                    } else {
                        setYellowMark(null);
                    }
                })
                .catch((err) => showError(err));
        }
    }, [organization?.id]);

    useEffect(() => {
        if (organizationNumber) {
            api.get(`${spleisUrl}/api/admin/invoice/orgNumber/${organizationNumber}`)
                .then((res) => {
                    setInvoices(res.filter((invoice) => !invoice.creditnote_at));
                    setCreditedInvoices(res.filter((invoice) => invoice.creditnote_at));
                })
                .catch((err) => showError('Feil ved henting av fakturaer'));
        }
    }, [organizationNumber]);

    const setVerificationStatus = (verification_status) => {
        api.put(`${spleisUrl}/api/admin/organization/${match.params.id}/verification-status`, {
            verification_status,
        })
            .then(() => {
                showInfo('Endret verifiseringsstatus');
                refreshData();
                window.notify();
            })
            .catch(() => {
                showError('Kunne ikke endre verifiseringsstatus');
            });
    };

    const verifyOrganization = () => {
        api.put(`${spleisUrl}/api/admin/organization/${match.params.id}/verify`)
            .then(() => {
                showInfo('Organisasjonen er verifisert!');
                setVerificationStatus(
                    _.findKey(VERIFICATION_STATUSES, (status) => status === VERIFICATION_STATUSES.VERIFIED)
                );
                refreshData();
            })
            .catch((error) => {
                showError(error);
            });
    };

    const toggleHiddenStatus = () => {
        const newHiddenValue = !organization.hidden;
        api.put(`${spleisUrl}/api/admin/organization/${match.params.id}/hide`, { hidden: newHiddenValue })
            .then(() => {
                showInfo(`Organisasjonen ${newHiddenValue ? 'blir skjult' : 'blir synlig'}`);
                refreshData();
            })
            .catch((error) => showError(error));
    };

    const handleOnAcceptContact = (contact) => {
        api.post(
            `${spleisUrl}/api/admin/organization/${contact.org_id}/contact-candidates/${contact.user_id}/promote`
        ).then(() => window.notify());
    };

    const handleOnDenyContact = (contact) => {
        api.remove(
            `${spleisUrl}/api/admin/organization/${contact.org_id}/contact-candidates/${contact.user_id}`
        ).then(() => window.notify());
    };

    const verifyBankAccount = () => {
        api.put(`${spleisUrl}/api/admin/bank-account/${bankAccount.id}/verify`)
            .then(() => {
                showInfo('Bankkonto er verifisert!');
                refreshData();
            })
            .catch((error) => {
                showError(error);
            });
    };

    const verifyIsOwnerOfBankAccount = () => {
        api.post(`${spleisUrl}/api/admin/bank-account/${bankAccount.id}/verify-ownership`)
            .then(() => {
                showInfo('Sjekk mot KAR-registeret gjennomført');
                refreshData();
            })
            .catch((error) => {
                showError(error);
            });
    };

    const storeOrganizationName = (name) => {
        api.put(`${spleisUrl}/api/admin/organization/${match.params.id}/name`, {
            name,
        })
            .then(() => {
                refreshData();
                showInfo('Navn oppdatert!');
            })
            .catch((err) => {
                showError(`Feil endring av navn: ${err.text}`);
            });
    };

    const isOrgVerified = (organization, bankAccount, contactUsers) =>
        !!organization.verified_at &&
        !!bankAccount.verified_at &&
        !!contactUsers.filter((contactUser) => contactUser.verified_at).length > 0;

    const { id } = match.params;
    const hasAVerifiedContactPerson = contactUsers?.filter((contact) => contact?.verified_at).length > 0;

    if (!organization.id) {
        return null;
    }

    return (
        <TaskContainer
            detail={
                <div>
                    <Comments lookupKey="organization" id={id} postToSlack={false} />
                    {projectLimit && (
                        <div>
                            Viser maks {projectLimit} prosjekter
                            {projectLimit === projects.length && (
                                <Button onClick={increaseProjectLimit}>Hent mer</Button>
                            )}
                        </div>
                    )}
                    <ProjectList data={projects} projectLimit={projectLimit} />
                </div>
            }
        >
            <Helmet>
                <title>{organization.name ?? ''}</title>
            </Helmet>
            <div className="task-header">
                <OrganizationInfo
                    editSaveCallback={storeOrganizationName}
                    editable={true}
                    organization_id={organization.id}
                    organization={organization}
                    size="large"
                />
            </div>
            <div className="task-actions">
                {!isOrgVerified(organization, bankAccount, contactUsers) && (
                    <div>
                        <span className="status-select-desctiption">Status: </span>
                        <select
                            className="status-select"
                            value={organization.verification_status || VERIFICATION_STATUSES.TODO}
                            onChange={(e) => setVerificationStatus(e.target.value)}
                        >
                            {Object.keys(VERIFICATION_STATUSES).map((statusKey) => (
                                <option key={statusKey} value={statusKey}>
                                    {VERIFICATION_STATUSES[statusKey]}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {bankAccount.verified_at && hasAVerifiedContactPerson && !organization.verified_at && (
                    <ConfirmButton
                        title="Verifiser organisasjon"
                        message="Er du sikker på at du vil verifisere organisasjonen?"
                        type="button"
                        onClick={verifyOrganization}
                    />
                )}
                <ChangeAccountNumberButton
                    organizationId={organization.id}
                    bankAccountNumber={bankAccount.account_number}
                    refreshData={refreshData}
                />
                {bankAccount.id && !bankAccount.verified_at && (
                    <ConfirmButton
                        title="Verifiser konto"
                        message="Er du sikker på at du vil verifisere kontoen"
                        onClick={verifyBankAccount}
                    />
                )}
                {!!bankAccount.account_number && (
                    <Button onClick={verifyIsOwnerOfBankAccount}>Sjekk KAR-register</Button>
                )}
                <Button onClick={toggleHiddenStatus}>{organization.hidden ? 'Vis' : 'Skjul'} organisasjon</Button>
                <AddOrEditContactPersonButton orgId={organization.id} refreshData={refreshData} />
            </div>
            {yellowMark && <Banner title={yellowMark} themeClass="warning" />}
            <div className="task-body org-container">
                <div className="org-subscription-container">
                    <Subscription organizationId={organization?.id} />
                </div>
                <MainContacts contactUsers={contactUsers} refreshData={refreshData} organization={organization} />
                <NifContacts
                    contactUsers={nifContacts}
                    spleisContacts={contactUsers}
                    refreshData={refreshData}
                    organization={organization}
                />
                <div className={'bankAccounts'}>
                    <BankAccount mainAccount={bankAccount} organization={organization} />
                    {nifBankAccount && (
                        <NifBankAccountNumber
                            accountNumber={nifBankAccount}
                            organization={organization}
                            refreshData={refreshData}
                        />
                    )}
                </div>
                {contactCandidates.length > 0 && (
                    <ContactCandidates
                        contacts={contactCandidates}
                        onAccept={handleOnAcceptContact}
                        onDeny={handleOnDenyContact}
                    />
                )}
                <div className="org-sok-container">
                    <span>Søk i Brønnøysundregistrene: </span>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${organization.organization_number}`}
                    >
                        brreg.no/{organization.organization_number}
                    </a>
                </div>
                <div className="org-sok-container">
                    <span>Organisasjonen på spleis.no: </span>
                    <a target="_blank" rel="noopener noreferrer" href={`${spleisFrontUrl}/org/${organization.id}`}>
                        spleis.no/org/{organization.id}
                    </a>
                </div>
                <div className="org-sok-container">
                    <span>URL for organisasjonsregistrering: </span>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.spleis.no/organization/register/${organization.organization_number}`}
                    >
                        spleis.no/organization/register/{organization.organization_number}
                    </a>
                </div>
            </div>
            <InvoiceTable title="Fakturaer" data={invoices} />
            <InvoiceTable title="Krediterte fakturaer" data={creditedInvoices} />
        </TaskContainer>
    );
};

class OrgWithData extends Component {
    state = {
        organization: {},
        bankAccount: {},
        contactCandidates: [],
        contactUsers: [],
        nifContacts: [],
        projectLimit: 5000,
    };

    componentDidMount() {
        this.fetchData();
        window.subscribe(this.fetchData);
    }

    componentWillUnmount() {
        window.unsubscribe(this.fetchData);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.fetchData(nextProps.match.params.id);
        }
    }

    componentDidUpdate(_, prevState) {
        if (prevState.projectLimit !== this.state.projectLimit) {
            this.fetchOrganizationProjects(this.props.match.params.id);
        }
    }

    fetchData = (newId) => {
        const orgId = newId || this.props.match.params.id;
        this.fetchOrganization(orgId);
        this.fetchOrganizationProjects(orgId);
        this.fetchOrganizationContacts(orgId);
        this.fetchOrganizationNifContacts(orgId);
        this.fetchOrganizationNifBankInfo(orgId);
        this.fetchMainAccount(orgId);
        this.fetchContactCandidates(orgId);
    };

    fetchContactCandidates = (selectedOrganizationId) => {
        if (!!selectedOrganizationId) {
            api.get(`${spleisUrl}/api/admin/organization/${selectedOrganizationId}/contact-candidates`)
                .then((contactCandidates) => {
                    const userPromises = contactCandidates.map((contact) =>
                        api
                            .get(`${spleisUrl}/api/admin/user/${contact.user_id}`)
                            .then((user) => ({ ...user, ...contact }))
                    );
                    return Promise.all(userPromises);
                })
                .then((contactCandidates) => this.setState({ contactCandidates }))
                .catch((err) => console.error(err));
        }
    };

    fetchOrganization = (id) => {
        if (id) {
            api.get(`${spleisUrl}/api/admin/organization/${id}`)
                .then((organization) => {
                    this.setState({
                        organization,
                    });
                })
                .catch((err) => showError(err));
        }
    };

    fetchOrganizationProjects = (id) => {
        if (id) {
            const projectLimit = this.state.projectLimit;
            api.get(`${spleisUrl}/api/admin/project/organization/${id}${projectLimit ? `?limit=${projectLimit}` : ''}`)
                .then((projects) => {
                    this.setState({
                        projects,
                    });
                })
                .catch((err) => showError(err));
        }
    };

    fetchOrganizationContacts = (id) => {
        if (id) {
            api.get(`${spleisUrl}/api/admin/organization/${id}/contacts`)
                .then((contacts) => {
                    this.setState({
                        contactUsers: contacts,
                    });
                })
                .catch((err) => showError(err));
        }
    };
    fetchOrganizationNifContacts = (id) => {
        if (id) {
            api.get(`${spleisUrl}/api/admin/organization/${id}/nif-contacts`)
                .then((contacts) => {
                    this.setState({
                        nifContacts: contacts,
                    });
                })
                .catch((err) => showError(err));
        }
    };
    fetchOrganizationNifBankInfo = (id) => {
        if (id) {
            api.get(`${spleisUrl}/api/admin/organization/${id}/nif-bank-info`)
                .then((response) => {
                    this.setState({
                        nifBankAccount: response?.bankAccount,
                    });
                })
                .catch((err) => showError(err));
        }
    };

    fetchMainAccount = (orgId) => {
        if (orgId) {
            api.get(`${spleisUrl}/api/admin/bank-account/main-account-for-organization/${orgId}`)
                .then((res) =>
                    this.setState({
                        bankAccount: res,
                    })
                )
                .catch((err) => showError(JSON.stringify(err)));
        }
    };

    render() {
        return (
            <Org
                organization={this.state.organization}
                projects={this.state.projects}
                projectLimit={this.state.projectLimit}
                increaseProjectLimit={() => this.setState({ projectLimit: this.state.projectLimit + 100 })}
                contactCandidates={this.state.contactCandidates}
                bankAccount={this.state.bankAccount}
                nifBankAccount={this.state.nifBankAccount}
                contactUsers={this.state.contactUsers}
                nifContacts={this.state.nifContacts}
                refreshData={this.fetchData}
                {...this.props}
            />
        );
    }
}
export default OrgWithData;
