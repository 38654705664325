import React, { useState } from 'react';
import TaskContainer from '../TaskContainer/TaskContainer';
import api from '../api';
import { spleisUrl } from '../config';
import styles from './Transactions.module.scss';
import { Helmet } from 'react-helmet';
import Button from '../Button/Button';

const Transactions = ({ match }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searching, setSearching] = useState(null);
    const [foundNothing, setFoundNothing] = useState(false);

    const fetchTransactions = () => {
        let url;
        if (!searchTerm) {
            setFoundNothing(true);
            return;
        } else if (/^[1-9]\d*$/.test(searchTerm)) {
            url = `${spleisUrl}/api/admin/transaction/vipps/transaction-id/${encodeURI(searchTerm)}`
        } else if (searchTerm.startsWith('pi_')) {
            url = `${spleisUrl}/api/admin/transaction/stripe/intent/${encodeURI(searchTerm)}`
        } else {
            setFoundNothing(true);
            return;
        }
        setSearching(true);
        setFoundNothing(false);
        api.get(url)
            .then((vippsTransaction) => {
                if (vippsTransaction?.id) {
                    window.location = `/transaction/${vippsTransaction.transaction_id}`;
                } else {
                    setFoundNothing(true);
                }
            })
            .catch(() => setFoundNothing(true))
            .finally(() => setSearching(false))
    };

    return (
        <TaskContainer>
            <Helmet>
                <title>Transaksjoner</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">Transaksjoner</h1>
            </header>
            <div className="task-body">
                <h2>Finn transaksjon</h2>
                <div>
                    <input
                        disabled={searching}
                        placeholder="Vipps/stripe ID"
                        className={styles.searchInput}
                        onKeyDown={(e) => e.key === 'Enter' && fetchTransactions()}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value || '')}
                    />
                    <Button disabled={searching || !searchTerm} className="btn" onClick={fetchTransactions}>
                        {searching ? 'Søker...' : 'Søk'}
                    </Button>
                </div>
                {foundNothing && <div>Fant ingen transaksjoner</div>}
            </div>
        </TaskContainer>
    );
};

export default Transactions;
