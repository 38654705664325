import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import api from '../api';
import { spleisUrl } from '../config';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import Button from '../Button/Button';
import HelmetForSubpages from '../HelmetForSubpages/HelmetForSubpages';
import { showError, showInfo } from '../store';
import TaskContainer from '../TaskContainer/TaskContainer';

const Inventory = () => {
    const [inventories, setInventories] = useState([]);
    const [productRewardsNotInInventory, setProductRewardsNotInInventory] = useState([]);
    const fetchData = useCallback(async () => {
        try {
            const inventories = await api.get(`${spleisUrl}/api/admin/product-reward-inventory/all`);
            const productRewards = await api.get(
                `${spleisUrl}/api/admin/product-reward-inventory/products-not-in-inventory`
            );
            setInventories(inventories);
            setProductRewardsNotInInventory(productRewards);
        } catch (err) {
            showError('Kunne ikke hente varetelling');
        }
    }, []);

    useEffect(() => {
        fetchData();
        window.subscribe(fetchData);
        return () => window.unsubscribe(fetchData);
    }, [fetchData]);

    const updateAmount = async (e) => {
        e.preventDefault();
        try {
            const form = e.currentTarget;
            const formData = new FormData(form);
            await api.post(`${spleisUrl}/api/admin/product-reward-inventory/upsert`, {
                productRewardId: formData.get('productRewardId'),
                inventory: formData.get('amount'),
            });
            showInfo('Varetelling oppdatert');
            window.notify();
        } catch {
            showError('Kunne ikke oppdatere varetelling');
        }
    };

    const remove = async (productRewardId) => {
        try {
            await api.remove(`${spleisUrl}/api/admin/product-reward-inventory/${productRewardId}`);
            showInfo('Produkt fjernet fra varetelling');
            window.notify();
        } catch {
            showError('Kunne ikke oppdatere varetelling');
        }
    };

    const setAlertThreshold = async (e) => {
        e.preventDefault();
        try {
            const form = e.currentTarget;
            const formData = new FormData(form);
            await api.post(
                `${spleisUrl}/api/admin/product-reward-inventory/${formData.get(
                    'productRewardId'
                )}/set-alert-threshold`,
                {
                    alertThreshold: formData.get('amount'),
                }
            );
            showInfo('Varselgrense oppdatert');
            window.notify();
        } catch {
            showError('Kunne ikke oppdatere varselgrense');
        }
    };

    const removeAlertThreshold = async (productRewardId) => {
        try {
            await api.post(`${spleisUrl}/api/admin/product-reward-inventory/${productRewardId}/set-alert-threshold`, {
                alertThreshold: null,
            });
            showInfo('Varselgrense fjernet');
            window.notify();
        } catch {
            showError('Kunne ikke fjerne varselgrense');
        }
    };

    return (
        <TaskContainer>
            <HelmetForSubpages title="Varetelling" />
            <header className="task-header">
                <h1 className="task-title">Varetelling</h1>
            </header>

            <div className="task-body">
                <table className={'inventory-table table table-striped'}>
                    <thead>
                        <tr>
                            <th>Produkt ID</th>
                            <th>Produktnavn</th>
                            <th>Antall på lager</th>
                            <th>Varselgrense</th>
                            <th>Sett nytt antall</th>
                            <th>Sett varselgrense</th>
                        </tr>
                    </thead>
                    <tbody>
                        {inventories.map((inventory) => (
                            <tr key={inventory.id}>
                                <td>{inventory.product_reward_id}</td>
                                <td>{inventory.title}</td>
                                <td>{inventory.inventory}</td>
                                <td>{Number.isFinite(inventory.alert_threshold) ? inventory.alert_threshold : '-'}</td>
                                <td>
                                    <form onSubmit={updateAmount}>
                                        <UpdateAmount productRewardId={inventory.product_reward_id} />
                                        <ConfirmButton
                                            title="❌"
                                            type="button"
                                            onClick={() => remove(inventory.product_reward_id)}
                                            message={`Er du sikker på at du vil fjerne «${inventory.title}» fra varetellinga? Dette vil ikke fjerne det fra salg.`}
                                        />
                                    </form>
                                </td>
                                <td>
                                    <form onSubmit={setAlertThreshold}>
                                        <UpdateAmount
                                            productRewardId={inventory.product_reward_id}
                                            defaultValue={inventory.alert_threshold}
                                        />
                                        <ConfirmButton
                                            title="❌"
                                            type="button"
                                            onClick={() => removeAlertThreshold(inventory.product_reward_id)}
                                            message={`Er du sikker på at du vil fjerne varselgrense for «${inventory.title}»? Det vil fortsatt komme varsling om det går tomt.`}
                                        />
                                    </form>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {productRewardsNotInInventory.length > 0 && (
                    <>
                        <h2>Legg til produkt i varetelling</h2>

                        <form onSubmit={updateAmount}>
                            <AddProductToInventory productRewardsNotInInventory={productRewardsNotInInventory} />
                        </form>
                    </>
                )}
            </div>
        </TaskContainer>
    );
};

export default Inventory;

function AddProductToInventory({ productRewardsNotInInventory }) {
    const [productRewardId, setProductRewardId] = useState(productRewardsNotInInventory[0]?.id);
    return (
        <table className={'invoice-table table table-striped'}>
            <thead>
                <tr>
                    <th>Velg produkt</th>
                    <th>Antall på lager</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <select value={productRewardId} onChange={(e) => setProductRewardId(e.target.value)}>
                            {productRewardsNotInInventory.map((productReward) => (
                                <option value={productReward.id}>{productReward.title}</option>
                            ))}
                        </select>
                    </td>
                    <td>
                        <UpdateAmount productRewardId={productRewardId} />
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

function UpdateAmount({ productRewardId, defaultValue }) {
    return (
        <>
            <input name="productRewardId" hidden value={productRewardId} />
            <input name="amount" type="number" min="0" required size="5" defaultValue={defaultValue} />
            <Button type="submit">✅</Button>
        </>
    );
}
