import { auth } from './auth';

function request({ method, path, headers = {}, body, stream, binary }) {
    if (path.includes('spleis.no') || path.includes('localhost') || path.startsWith('/')) {
        headers.Authorization = `Bearer ${auth.getIdToken()}`;
    }
    if (stream) {
        return fetch(path, { headers, body, method });
    } else {
        return new Promise((resolve, reject) => {
            fetch(path, { headers, body, method })
                .then(async (res) => {
                    const isJson = (res.headers.get('content-type') || '').indexOf('application/json') !== -1;
                    const body = isJson ? await res.json() : binary ? await res.blob() : await res.text();
                    if (res.status >= 200 && res.status < 300) {
                        return resolve(body);
                    } else {
                        if (body.errors) {
                            return reject(body.errors.global);
                        } else {
                            return reject(`${method} ${path} returnerte ${res.status}: ${body.toString()}`);
                        }
                    }
                })
                .catch((err) => {
                    console.error(err);
                    reject(`${method} ${path} feilet: ${err.toString()}`);
                });
        });
    }
}

function get(path, options) {
    return request({ method: 'GET', path, binary: options?.binary, stream: options?.stream });
}

function post(path, body, headers, stream) {
    return request({
        method: 'POST',
        path,
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
        body: JSON.stringify(body),
        stream,
    });
}

function put(path, body) {
    return request({
        method: 'PUT',
        path,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    });
}

function remove(path, body) {
    return request({
        method: 'DELETE',
        path,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    });
}

function upload(path, method, file, crop) {
    const form = new FormData();
    form.append('image', file);
    form.append('crop', JSON.stringify(crop));
    return request({
        method,
        path,
        body: form,
    });
}

export default {
    get,
    post,
    put,
    remove,
    upload,
};
