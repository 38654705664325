import React, { useEffect, useState } from 'react';
import TaskContainer from '../TaskContainer/TaskContainer';
import { Link } from 'react-router-dom';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import InfoDataList from '../InfoDataList/InfoDataList';
import { formatDate } from '../dates';
import api from '../api';
import { spleisFrontUrl, spleisUrl } from '../config';
import { showError, showInfo } from '../store';
import Banner from '../Banner/Banner';
import ProjectLink from '../SmartLinks/ProjectLink';
import UserLink from '../SmartLinks/UserLink';
import styles from './Transaction.module.scss';
import { formatMoney, formatMoneyKr } from '../formatters';
import { Helmet } from 'react-helmet';

const mapPaymentMethod = ({ transaction, invoiceId, stripeIntentId, vippsTid }) => {
    if (vippsTid) {
        return `Vipps (${vippsTid})`
    } else if (transaction.vipps_id) {
        return `Vipps`;
    } else if (transaction.stripe_id) {
        if (stripeIntentId) {
            return (
                <a href={`https://dashboard.stripe.com/payments/${stripeIntentId}`} target={'_blank'}>
                    Stripe
                </a>
            );
        }
        return `Stripe`;
    } else if (invoiceId) {
        return <a href={`/invoice/${invoiceId}`}>Faktura</a>;
    }
};

const TransactionInfo = ({ transaction, refund, invoice, stripeIntentId, vippsTid }) => {
    if (!transaction) return null;
    return (
        <div className={styles.transactionInfoWrapper}>
            <div className={styles.transactionInfoElement}>ID: {transaction.id}</div>
            <div className={styles.transactionInfoElement}>Beløp: {formatMoneyKr(transaction.amount)}</div>
            <div className={styles.transactionInfoElement}>Innbetalt: {formatDate(transaction.created_at)}</div>
            {refund && <div className={styles.transactionInfoElement}>Refundert: {formatDate(refund.refunded_at)}</div>}
            <div className={styles.transactionInfoElement}>Bruker: {<UserLink id={transaction.from_user} />}</div>
            <div className={styles.transactionInfoElement}>Spleis: {<ProjectLink id={transaction.to_project} />}</div>
            <div className={styles.transactionInfoElement}>
                Betalingsmåte: {mapPaymentMethod({ transaction, invoiceId: invoice?.db.id, stripeIntentId, vippsTid })}
            </div>
        </div>
    );
};

const CommentsList = ({ comments }) => {
    if (comments?.length === 0) {
        return null;
    }
    return (
        <div className={styles.transactionInfoWrapper}>
            <h2>Kommentarer</h2>
            <ul>
                {comments.map((comment) => {
                    return (
                        <li key={comment.id} className={comment.hidden ? styles.hiddenComment : ''}>
                            {comment.comment}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

const OrderTable = ({ rewards, productSentStatus }) => {
    const shortenText = (text, max) => {
        if (text && text.length > max) {
            return text.substring(0, max - 1) + '..';
        } else {
            return text;
        }
    };

    const getAddress = (giver_address) => {
        if (giver_address) {
            return (
                <div>
                    {giver_address.name + ', '}
                    {giver_address.address + ', '}
                    {giver_address.postalCode + ', '}
                    {giver_address.postalName}
                </div>
            );
        } else {
            return <div />;
        }
    };

    return (
        <section className="rewards-container">
            <div className="project-table-data">
                <header className="project-table-data-header">
                    <h2>Belønninger</h2>
                </header>
                {rewards.length > 0 ? (
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Kjøpt</th>
                                <th>Tittel</th>
                                <th>Pris</th>
                                <th>Adresse?</th>
                                <th>Sendt</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rewards.map((reward) => (
                                <tr key={reward.id}>
                                    <td>{reward.id}</td>
                                    <td>{reward.purchased_count}</td>
                                    <td className="rewards-cell">{shortenText(reward.title, 200)}</td>
                                    <td>{formatMoney(reward.price)}</td>
                                    <td>{reward.requires_address ? getAddress(reward.metadata?.giver_address) : ''}</td>
                                    <td>
                                        {productSentStatus.find((p) => p.reward_id === reward.id)?.sent_at
                                            ? 'Sendt'
                                            : 'Ikke sendt'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>Ingen belønninger</p>
                )}
            </div>
        </section>
    );
};

const Transaction = ({ match }) => {
    const [loading, setLoading] = useState(false);
    const [transaction, setTransaction] = useState(null);
    const [refund, setRefund] = useState(null);
    const [rewards, setRewards] = useState(null);
    const [invoice, setInvoice] = useState(null);
    const [refunding, setRefunding] = useState(false);
    const [isSendingReceipt, setIsSendingReceipt] = useState(false);
    const [receiptEmail, setReceiptEmail] = useState('');
    const [comments, setComments] = useState([]);
    const [productSentStatus, setProductSentStatus] = useState([]);
    const [anyProductsSent, setAnyProductsSent] = useState(false);
    const [vippsTransaction, setVippsTransaction] = useState(null);
    const [stripeTransaction, setStripeTransaction] = useState(null);
    const transactionId = match.params.transactionId;

    const getTransaction = (transactionId) => {
        setLoading(true);
        api.get(`${spleisUrl}/api/admin/transaction/${transactionId}`)
            .then((res) => {
                setTransaction(res);
                if (res.stripe_id) {
                    api.get(`${spleisUrl}/api/admin/transaction/stripe/${res.stripe_id}`)
                        .then((stripeRes) => {
                            setStripeTransaction(stripeRes);
                        })
                        .catch((err) => {
                            showError('Feil ved henting av stripe payment intent', err.text);
                        });
                } else if (res.vipps_id) {
                    api.get(`${spleisUrl}/api/admin/transaction/vipps/${res.vipps_id}`)
                        .then((vippsRes) => {
                            setVippsTransaction(vippsRes);
                        })
                        .catch((err) => {
                            showError('Feil ved henting av vipps transaksjon', err.text);
                        });
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                showError('Feil ved henting av transaksjon', err.text);
            });
    };

    const getProductSentStatus = (transactionId) => {
        setLoading(true);
        api.get(`${spleisUrl}/api/admin/transaction/${transactionId}/product-sent-status`)
            .then((res) => {
                setLoading(false);
                setProductSentStatus(res);
                setAnyProductsSent(!!res.find((p) => p.sent_at));
            })
            .catch((err) => {
                setLoading(false);
                showError('Feil ved henting av transaksjon', err.text);
            });
    };

    const getComments = (transactionId) => {
        setLoading(true);
        api.get(`${spleisUrl}/api/admin/transaction/${transactionId}/comments`)
            .then((res) => {
                if (res) {
                    setComments(res.comments);
                }
            })
            .catch((err) => {
                showError('Feil ved henting av kommentarer', err.text);
            });
    };

    const getRefund = (transactionId) => {
        setLoading(true);
        api.get(`${spleisUrl}/api/admin/transaction/${transactionId}/refund`)
            .then((res) => {
                setLoading(false);
                setRefund(res);
            })
            .catch((err) => {
                setLoading(false);
                showError('Feil ved henting av refundering', err.text);
            });
    };

    const getRewards = (transactionId) => {
        setLoading(true);
        api.get(`${spleisUrl}/api/admin/transaction/${transactionId}/rewards`)
            .then((res) => {
                setLoading(false);
                setRewards(res);
            })
            .catch((err) => {
                setLoading(false);
                showError('Feil ved henting av belønning', err.text);
            });
    };

    const refundTransaction = (transactionId) => {
        setRefunding(true);
        api.put(`${spleisUrl}/api/admin/transaction/${transactionId}/refund`)
            .then((res) => {
                setRefunding(false);
                setRefund(res);
                showInfo('Transaksjon refundert');
            })
            .catch((err) => {
                setRefunding(false);
                showError(err);
            });
    };

    const resendReceipt = (transactionId) => {
        setIsSendingReceipt(true);
        api.post(`${spleisUrl}/api/admin/transaction/${transactionId}/send-receipt`)
            .then((res) => {
                setIsSendingReceipt(false);
                showInfo('Kvittering sendt');
            })
            .catch((err) => {
                setIsSendingReceipt(false);
                showError(err);
            });
    };

    const resendReceiptToEmail = (transactionId, email) => {
        setIsSendingReceipt(true);
        api.post(`${spleisUrl}/api/admin/transaction/${transactionId}/send-receipt-to-email`, { email })
            .then((res) => {
                setIsSendingReceipt(false);
                setReceiptEmail('');
                showInfo('Kvittering sendt');
            })
            .catch((err) => {
                setIsSendingReceipt(false);
                showError(err);
            });
    };

    const getInvoice = (invoiceNumber) => {
        setLoading(true);
        api.get(`${spleisUrl}/api/admin/invoice/invoiceNumber/${invoiceNumber}`)
            .then((res) => {
                setLoading(false);
                setInvoice(res);
            })
            .catch((err) => {
                setLoading(false);
                showError('Feil ved henting av faktura', err.text);
            });
    };

    const anonymize = (transactionId) => {
        api.post(`${spleisUrl}/api/admin/transaction/${transactionId}/make-anonymous`)
            .then((res) => {
                showInfo('Transaksjonen ble anonym');
                window.notify();
            })
            .catch((err) => {
                showError(err);
            });
    };

    useEffect(() => {
        const update = () => {
            getTransaction(transactionId);
            getRefund(transactionId);
            getComments(transactionId);
            getRewards(transactionId);
            getProductSentStatus(transactionId);
        };
        update();
        window.subscribe(update);
        return () => window.unsubscribe(update);
    }, [transactionId]);

    useEffect(() => {
        if (transaction?.invoice_number) {
            getInvoice(transaction.invoice_number);
        }
    }, [transaction?.invoice_number]);

    if (loading) {
        return <span style={{ fontStyle: 'italic' }}>Laster...</span>;
    }

    if (!transaction) {
        return <span>Fant ingen transaksjon</span>;
    }

    const transactionIsRefunded = !!refund?.id;
    const visibleCommentCount = comments.filter((c) => !c.hidden).length;

    return (
        <TaskContainer>
            <Helmet>
                <title>Transaksjon {transactionId}</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">Transaksjon {transaction.id}</h1>
                <Link className="back-link" to="/invoice/">
                    &larr; Tilbake
                </Link>

                <a href={''} target="_blank" rel="noopener noreferrer">
                    {''}
                </a>
            </header>
            {transactionIsRefunded && (
                <Banner title={`Innbetalingen ble refundert ${formatDate(refund.refunded_at)}`} themeClass="warning" />
            )}
            {!transactionIsRefunded && (
                <div className={`task-actions task-actions--split`}>
                    <div>
                        <ConfirmButton
                            title="Send kvittering"
                            disabled={!transaction || isSendingReceipt}
                            className="task-action-button"
                            message={`Send kvittering på nytt til brukeren`}
                            onClick={() => resendReceipt(transactionId)}
                        />
                        <ConfirmButton
                            title="Send kvittering til epost"
                            disabled={!transaction || isSendingReceipt}
                            className="task-action-button"
                            onClick={() => resendReceiptToEmail(transactionId, receiptEmail)}
                        >
                            <input
                                type="email"
                                value={receiptEmail}
                                onChange={(e) => setReceiptEmail(e.target.value)}
                                placeholder="Epost"
                            />
                        </ConfirmButton>
                        <a
                            className="btn task-action-button"
                            href={`${spleisFrontUrl}/project/${transaction.to_project}/payment/${transaction.id}/${transaction.update_nonce}`}
                            target={'_blank'}
                        >
                            Giverliste/hilsen/stor giver
                        </a>
                        <ConfirmButton
                            title="Gjør anonym"
                            disabled={transaction?.from_user_meta_data?.anonymous && visibleCommentCount === 0}
                            className="task-action-button"
                            message={`Gjør transaksjonen anonym og gjem kommentarer`}
                            onClick={() => anonymize(transactionId)}
                        />
                    </div>
                    <ConfirmButton
                        title="Refunder"
                        disabled={refunding || !transaction}
                        className="task-action-button dangerous-button"
                        message={
                            anyProductsSent
                                ? 'Dette kjøpet er sendt til kunden. Denne transaksjonen kan i utgangspunktet ikke refunderes, er du helt sikker?'
                                : 'Er du helt sikker på at du vil refundere transaksjonen? Dette kan ikke gjøres om'
                        }
                        onClick={() => refundTransaction(transactionId)}
                    />
                </div>
            )}
            <div className="task-body">
                <h2>Info</h2>
                <TransactionInfo
                    transaction={transaction}
                    refund={refund}
                    invoice={invoice}
                    stripeIntentId={stripeTransaction?.stripe_intent_id}
                    vippsTid={vippsTransaction?.vipps_tid}
                />
                <CommentsList comments={comments} />
                <h2>Rådata</h2>
                <InfoDataList title={'Transaction'} object={transaction} />
                {refund && <InfoDataList title={'Refund'} object={refund} />}
                {rewards && <OrderTable rewards={rewards} productSentStatus={productSentStatus} />}
            </div>
        </TaskContainer>
    );
};

export default Transaction;
