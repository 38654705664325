export const presets = [
    {
        optionText: 'Endret formål',
        message: `Det opprinnelige formålet i spleisen kan ikke lenger gjennomføres som forventet, og i den forbindelse er det nå mulig å be om refusjon for bidraget du ga.

Du kan se oppdateringen på spleisen for informasjon om hvorfor formålet måtte endres.

Dersom du ønsker å la donasjonen stå og støtte det nye formålet, er det ikke noe du trenger å foreta deg.`,
    },
    {
        optionText: 'Endret mottaker',
        message: `Mottaker av pengene i spleisen er endret til {receiverName} og i den forbindelse er det nå mulig å be om refusjon for bidraget du ga.

Du kan se oppdateringen på spleisen for informasjon om hvorfor formålet måtte endres.

Dersom du ønsker å la donasjonen stå, og la pengene utbetales til den nye mottakeren, er det ikke noe du trenger å foreta deg.`,
    },
    {
        optionText: 'Loddsalg',
        message: `En spleis kan ikke omfatte lodd/trekning av gevinster til givere som bidrar til en innsamling, med mindre gevinsten er av ubetydelig økonomisk verdi. 

I den forbindelse er det nå mulig å be om refusjon for bidraget du ga, siden loddtrekningen ikke kan tillates å gjennomføres. 

Dersom du ønsker å la donasjonen din stå som en ren donasjon, er det ikke noe du trenger å foreta deg.`,
    },
    {
        optionText: '(blank)',
        message: '',
    },
];
