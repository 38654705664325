import React, { useState } from 'react';
import TaskContainer from '../TaskContainer/TaskContainer';
import api from '../api';
import { spleisUrl } from '../config';
import { showError } from '../store';
import Button from '../Button/Button';
import { formatMoneyKr } from '../formatters';
import './PotentialIncome.scss';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useHeavyTaskPoller } from './useHeavyTaskPoller';

const now = new Date();
const currentMonthPadded = ('0' + (now.getMonth() + 1)).slice(-2);
const firstDayOfMonth = `${now.getFullYear()}-${currentMonthPadded}-01`;

const PotentialIncome = () => {
    const [potentialIncome, setPotentialIncome] = useState();
    const [processingId, setProcessingId] = useState();
    const [fetching, setFetching] = useState(false);
    const [beforeDate, setBeforeDate] = useState(firstDayOfMonth);

    const doFetch = () => {
        setFetching(true);
        api.get(`${spleisUrl}/api/admin/project/potential-income-before-date/${beforeDate}`)
            .then((res) => setProcessingId(res.processingId))
            .catch(() => showError('Feil ved henting av potensiell inntjening'));
    };

    const generateCsv = () => {
        const csvRows =
            'ProsjektId,Link,Pris,SamletInn,AvgiftEtterMVA\n' +
            potentialIncome.projects
                .map((p) =>
                    [p.projectId, `https://www.spleis.no/project/${p.projectId}`, p.pricing, p.collected, p.fee].join(
                        ','
                    )
                )
                .join('\n');
        const a = document.createElement('a');
        a.style.display = 'none';
        a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(csvRows));
        a.download = 'potential-income.csv';
        document.body.appendChild(a);
        a.click();
    };

    useHeavyTaskPoller({
        processingId,
        setResult: (res) => {
            setPotentialIncome(res);
            setFetching(false);
        },
    });

    return (
        <TaskContainer>
            <Helmet>
                <title>Ikke utbetalt inntekt</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">Ikke utbetalt inntekt</h1>
            </header>
            <div className="task-body">
                <div className="potential-income-section">
                    <h2>Før dato</h2>
                    <input type="date" defaultValue={beforeDate} onChange={(e) => setBeforeDate(e.target.value)} />
                </div>
                <div className="potential-income-section">
                    <Button type="button" onClick={doFetch} disabled={fetching}>
                        {fetching ? 'Henter...' : 'Hent data'}
                    </Button>
                </div>
                {fetching && <div>Henter data med id {processingId}</div>}
                {potentialIncome && (
                    <div>
                        <div className="potential-income-section">
                            <div>Potensiell inntjening:</div>
                            <div>{formatMoneyKr(potentialIncome.potentialIncome)}</div>
                        </div>
                        <div className="potential-income-section">
                            <div>
                                <Button type="button" className="stats-csv-button" onClick={generateCsv}>
                                    Last ned csv
                                </Button>
                            </div>
                        </div>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Prosjekt</th>
                                    <th>Pris</th>
                                    <th>Samlet inn</th>
                                    <th>Avgift etter MVA</th>
                                </tr>
                            </thead>
                            <tbody>
                                {potentialIncome.projects.map((project) => (
                                    <tr key={project.projectId}>
                                        <td>
                                            <Link to={`/project/${project.projectId}`}>{project.projectId}</Link>
                                        </td>
                                        <td>{project.pricing}</td>
                                        <td>{formatMoneyKr(project.collected)}</td>
                                        <td>{formatMoneyKr(project.fee)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </TaskContainer>
    );
};

export default PotentialIncome;
