import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import VisibleIf from '../../VisibleIf/VisibleIf';
import ChangeOwner from '../ChangeOwner/ChangeOwner';
import ChangeReceiver from '../ChangeReceiver/ChangeReceiver';
import SendPayoutReminder from '../SendPayoutReminder/SendPayoutReminder';
import SetOrgWantsMoney from '../SetOrgWantsMoney/SetOrgWantsMoney';
import InvitationInfo from '../ProjectInvitationInfo';
import UserInfo from '../../User/UserInfo';
import OrganizationInfo from '../../Org/OrganizationInfo';
import styles from './OwnerAndReceiver.module.scss';
import RemoveProject from '../RemoveProject/RemoveProject';
import CreateMatchingGift from '../../MatchingGift/CreateMatchingGift';
import api from '../../api';
import { spleisUrl } from '../../config';
import SendMessageToOwner from '../SendMessageToOwner/SendMessageToOwner';
import OverrideOwnerName from '../OverrideOwnerName/OverrideOwnerName';
import RedMark from '../../User/RedMark/RedMark';
import { showError } from '../../store';
import Banner from '../../Banner/Banner';

const ProjectUserInfo = ({ user, title, projectId, profile, isReceiver, redMark, yellowMarkText }) => {
    if (!user || !user.id) {
        return (
            <div className="user-link">
                <h4 className="user-link-title">Invitert mottaker</h4>
                <InvitationInfo projectId={projectId} hideProjectForReceiver={profile?.hideProjectForReceiver} />
            </div>
        );
    }

    if (user.type === 'user') {
        return (
            <Link to={`/user/${user.id}`} className="user-link">
                <h4 className="user-link-title">{title}</h4>
                <UserInfo id={user.id} projectId={projectId} isReceiver={isReceiver} />
                <RedMark redMark={redMark} />
                {yellowMarkText && <Banner title={yellowMarkText} themeClass="warning" />}
            </Link>
        );
    }

    return (
        <Link to={`/organizations/${user.id}`} className="user-link-org">
            <h4 className="user-link-title">{title}</h4>
            <OrganizationInfo organization_id={user.id} />
            {yellowMarkText && <Banner title={yellowMarkText} themeClass="warning" />}
        </Link>
    );
};

const EventInfo = ({ project }) => {
    const [event, setEvent] = useState(null);
    const projectIsPartOfEvent = project.event_id && event?.name;
    useEffect(() => {
        if (project.event_id) {
            api.get(`${spleisUrl}/api/admin/event/${project.event_id}`)
                .then((result) => {
                    setEvent(result);
                })
                .catch((err) => console.log('Kunne ikke hente informasjon om eventet', { err, project }));
        }
    }, [project.event_id]);

    if (!projectIsPartOfEvent) {
        return null;
    }

    return (
        <div className={styles.eventInfoBox}>
            <p>Innsamlingen er en del av «{event?.name}»</p>
        </div>
    );
};

const OwnerAndReceiver = ({ project = {}, projectId }) => {
    const ownerIsReceiver = project.owner && project.receiver && project.owner.id === project.receiver.id;
    const overrideOwnerName = project?.profile?.overrideOwnerName;

    const [ownerRedMark, setOwnerRedMark] = useState(null);
    const [receiverRedMark, setReceiverRedMark] = useState(null);

    const [ownerYellowMark, setOwnerYellowMark] = useState(null);
    const [receiverYellowMark, setReceiverYellowMark] = useState(null);

    useEffect(() => {
        if (ownerRedMark) {
            setOwnerRedMark(null);
        }
        if (project?.owner?.type === 'user') {
            api.get(`${spleisUrl}/api/admin/user/${project?.owner?.id}/red-mark`)
                .then((res) => setOwnerRedMark(res.redMark))
                .catch((err) => showError(err));
        }
    }, [projectId, project?.owner?.id]);

    useEffect(() => {
        if (receiverRedMark) {
            setReceiverRedMark(null);
        }
        if (project?.receiver?.type === 'user') {
            api.get(`${spleisUrl}/api/admin/user/${project?.receiver?.id}/red-mark`)
                .then((res) => setReceiverRedMark(res.redMark))
                .catch((err) => showError(err));
        }
    }, [projectId, project?.receiver?.id]);

    const getYellowMarkForUser = (setMarkFn, id) => {
        api.get(`${spleisUrl}/api/admin/user/${id}/tagged-project-ids?tag=kundekontroll`)
            .then((res) => {
                if (res.projectIds?.length > 0) {
                    setMarkFn('Kundekontroll')
                } else {
                    setMarkFn(null)
                }
            })
            .catch((err) => showError(err));
    }

    const getYellowMarkForOrg = (setMarkFn, id) => {
        api.get(`${spleisUrl}/api/admin/organization/${id}/tagged-project-ids?tag=kundekontroll`)
            .then((res) => {
                if (res.projectIds?.length > 0) {
                    setMarkFn('Kundekontroll')
                } else {
                    setMarkFn(null)
                }
            })
            .catch((err) => showError(err));
    }

    useEffect(() => {
        setOwnerYellowMark(null);
        if (project?.owner?.type === 'user') {
            getYellowMarkForUser(setOwnerYellowMark, project?.owner?.id);
        } else if (project?.owner?.type === 'org') {
            getYellowMarkForOrg(setOwnerYellowMark, project?.owner?.id);
        }
    }, [projectId, project?.owner?.id]);

    useEffect(() => {
        setOwnerYellowMark(null);
        if (project?.receiver?.type === 'user') {
            getYellowMarkForUser(setReceiverYellowMark, project?.receiver?.id);
        } else if (project?.receiver?.type === 'org') {
            getYellowMarkForOrg(setReceiverYellowMark, project?.receiver?.id);
        }
    }, [projectId, project?.receiver?.id]);

    return (
        <section className={styles.projectAndReceiverInfo}>
            <div className={styles.projectUserLinks}>
                <div className={styles.profileBox}>
                    <ProjectUserInfo
                        user={project.owner}
                        title={ownerIsReceiver ? 'Innsamler & mottaker' : 'Innsamler'}
                        redMark={ownerRedMark}
                        yellowMarkText={ownerYellowMark}
                    />
                    {overrideOwnerName && (
                        <div className={styles.overrideOwnerName}>
                            <div className={styles.overrideOwnerNameTitle}>Navn som vises:</div>
                            <div className={styles.overrideOwnerNameName}>{overrideOwnerName}</div>
                        </div>
                    )}
                    <div className={styles.actions}>
                        <ChangeOwner project={project} />
                        {project?.id && <OverrideOwnerName project={project} />}
                        <RemoveProject project={project} />
                        {project?.id && <SendMessageToOwner project={project} />}
                    </div>
                </div>

                <div className={styles.profileBox}>
                    <VisibleIf isVisible={!ownerIsReceiver}>
                        <ProjectUserInfo
                            user={project.receiver}
                            projectId={projectId}
                            profile={project.profile}
                            title="Mottaker"
                            isReceiver
                            redMark={receiverRedMark}
                            yellowMarkText={receiverYellowMark}
                        />
                    </VisibleIf>

                    <EventInfo project={project} />

                    <div className={styles.actions}>
                        <ChangeReceiver project={project} />
                        <SendPayoutReminder project={project} />
                        <SetOrgWantsMoney project={project} />
                        <CreateMatchingGift project={project} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OwnerAndReceiver;
