import FormButton from '../FormButton/FormButton';
import React, { useState } from 'react';
import api from '../api';
import { spleisUrl } from '../config';
import Button from '../Button/Button';

const SalesUnitsForm = () => {
    const [organizationNumber, setOrganizationNumber] = useState();
    const [error, setError] = useState();
    const [salesUnits, setSalesUnits] = useState([]);
    const fetchSalesUnits = (e) => {
        e.preventDefault();
        if (!organizationNumber) {
            setError('Mangler organisasjonsnummer');
        }
        api.get(`${spleisUrl}/api/admin/vipps/partner/salesunits/${organizationNumber}`).then((response) => {
            if (response.status === 200) {
                setSalesUnits(response.salesUnits);
            } else if (response.status === 404) {
                setError('Ukjent organisasjonsnummer');
            }
        });
    };

    const updateOrganizationNumber = (organizationNumber) => {
        setError('');
        setSalesUnits([]);
        setOrganizationNumber(organizationNumber);
    };

    return (
        <div className={'salesUnitsForm'}>
            <form onSubmit={fetchSalesUnits}>
                <input
                    type={'text'}
                    value={organizationNumber}
                    onChange={(evt) => updateOrganizationNumber(evt.target.value)}
                    placeholder={'Organisasjonsnummer'}
                />
                <Button>Slå opp salgsenhet</Button>
                {error && <div className={'vippsError'}>{error}</div>}
                {salesUnits && salesUnits.length > 0 && (
                    <div>
                        {salesUnits.map((s) => (
                            <div>
                                <div>MSN: {s.msn}</div>
                                <div>Navn: {s.name}</div>
                            </div>
                        ))}
                    </div>
                )}
            </form>
        </div>
    );
};

export default SalesUnitsForm;
