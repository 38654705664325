import { useEffect, useRef } from 'react';
import api from '../api';
import { spleisUrl } from '../config';

export const useHeavyTaskPoller = ({ processingId, setResult }) => {
    const poll = useRef();

    useEffect(() => {
        const pollHeavyTask = async (fn, ms = 2000) => {
            let result = await fn();

            while (!(result.status === 'COMPLETE' || result.status === 'FAILED')) {
                await new Promise((resolve) => {
                    poll.current = setTimeout(resolve, ms);
                });
                result = await fn();
            }

            return result;
        };

        const checkResponse = async () => {
            const response = await pollHeavyTask(() => api.get(`${spleisUrl}/api/admin/heavy-tasks/${processingId}`));
            setResult(response.data);
        };

        if (processingId) {
            checkResponse();
        }

        return () => {
            if (poll.current) {
                clearTimeout(poll.current);
            }
        };
    }, [processingId]);
};
