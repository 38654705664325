import React, { useContext, useState } from 'react';
import { spleisUrl } from '../../config';
import api from '../../api';
import { showError, showInfo } from '../../store';
import ProjectContext from '../project-context';
import ConfirmButton from '../../ConfirmButton/ConfirmButton';
import { USER_TYPE } from '../../User/User';
import FormButton from '../../FormButton/FormButton';
import moment from 'moment';
import { ISO_DATE_FORMAT } from '../../dates';
import { presets } from './ToggleSelfServeRefundsReasonPresets.js';

const getReceiverName = (project) => {
    const receiver = project.receiver;
    let name = '<<FYLL INN MOTTAKER HER>>';
    if (receiver.type === USER_TYPE.USER) {
        if (receiver.verified_name) {
            name = receiver.verified_name;
        } else if (receiver.first && receiver.last) {
            name = `${receiver.first} ${receiver.last}`;
        } else if (receiver.name) {
            name = receiver.name;
        }
    } else if (receiver.type === USER_TYPE.ORG && receiver.name) {
        name = receiver.name;
    }
    return name;
};

const replacePlaceholders = (message, project) =>
    message ? message.replaceAll('{receiverName}', getReceiverName(project)) : '';

import styles from './ToggleSelfServeRefunds.module.scss';

const ToggleSelfServeRefunds = () => {
    const project = useContext(ProjectContext);
    const [timestamp, setTimestamp] = useState(moment(project.ends_at));
    const [deadline, setDeadline] = useState(moment(project.ends_at));

    const [message, setMessage] = useState(replacePlaceholders(presets.at(-1).message, project));
    const [selectedIndex, setSelectedIndex] = useState(presets.length - 1);

    const selfServeRefundEnabled = project?.can_self_serve_refund;

    const allowSelfServeRefund = async () => {
        try {
            await api.post(`${spleisUrl}/api/admin/project/${project.id}/allow-self-serve-refund`, {
                beforeTimestamp: timestamp ? moment(timestamp).utc().format() : undefined,
                deadline: deadline ? moment(deadline).format('YYYY-MM-DD') : undefined,
                reason: message,
            });
            showInfo('Givere kan nå refundere sitt bidrag, og informeres på e-post');
            window.notify();
        } catch (err) {
            showError(`Feil ved oppdatering av prosjekt: ${err.text}`);
        }
    };

    const disallowSelfServeRefund = async () => {
        try {
            await api.post(`${spleisUrl}/api/admin/project/${project.id}/disallow-self-serve-refund`);
            showInfo('Givere kan ikke lenger refundere sitt bidrag');
            window.notify();
        } catch (err) {
            showError(`Feil ved oppdatering av prosjekt: ${err.text}`);
        }
    };

    const selfServeRefundText = `${!selfServeRefundEnabled ? 'Aktiver' : 'Deaktiver'} selvbetjent refusjon`;

    const changePreset = (e) => {
        const preset = presets[e.target.value];
        setMessage(replacePlaceholders(preset.message, project));
        setSelectedIndex(e.target.value);
    };

    if (!selfServeRefundEnabled) {
        return (
            <FormButton
                title={`Tillat selvbetjent refusjon`}
                buttonText="Aktiver selvbetjent refusjon"
                successText="Aktiver selvbetjent refusjon"
                onClick={allowSelfServeRefund}
                doDoubleConfirm
                className="dangerous-button"
            >
                <div className={styles.modalForm}>
                    <p>Alle transaksjoner opprettet fram til tidspunktet som velges vil kunne refunderes.</p>
                    <label for="refund-before">Åpne refusjon for transaksjoner opprettet før:</label>
                    <input
                        name="refund-before"
                        type="datetime-local"
                        value={timestamp.format(ISO_DATE_FORMAT)}
                        onInput={(e) => {
                            setTimestamp(moment(e.target.value));
                        }}
                        autoFocus={true}
                    />
                    <label for="refund-deadline">Refusjonsfrist (standard er spleisens avslutningsdato):</label>
                    <input
                        name="refund-deadline"
                        type="date"
                        value={deadline.format('YYYY-MM-DD')}
                        onInput={(e) => {
                            setDeadline(moment(e.target.value));
                        }}
                    />
                    <div className={styles.presetWrapper}>
                        <label>Forhåndsdefinerte begrunnelser</label>
                        <select className={styles.preset} onChange={changePreset} value={selectedIndex}>
                            {presets.map((preset, index) => (
                                <option key={index} value={index}>
                                    {preset.optionText}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.messageWrapper}>
                        <label>Begrunnelse</label>
                        <textarea
                            className={styles.message}
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                        />
                        <p className={styles.modalNb}>
                            Viktig: Denne meldingen skal <b>ikke</b> inneholde lenker!
                        </p>
                    </div>
                    <p className={styles.modalNb}>
                        NB: Aktivering vil sende e-post til alle giverne som kan refundere!
                    </p>
                </div>
            </FormButton>
        );
    }

    return (
        <ConfirmButton
            className="dangerous-button"
            onClick={disallowSelfServeRefund}
            title={selfServeRefundText}
            successText={selfServeRefundText}
            message={`Er du sikker på at du vil deaktivere selvbetjent refusjon?`}
        />
    );
};

export default ToggleSelfServeRefunds;
