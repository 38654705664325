import React, { useEffect, useState } from 'react';
import { showError } from '../store';
import api from '../api';
import { spleisUrl } from '../config';
import './Invoice.scss';
import TaskContainer from '../TaskContainer/TaskContainer';
import InvoiceList from './InvoiceList';
import { Helmet } from 'react-helmet';
import InvoiceActions from './InvoiceActions';
import Button from '../Button/Button';

const Invoices = () => {
    const [notSent, setNotSent] = useState([]);
    const [notPaid, setNotPaid] = useState([]);
    const [toBeRefunded, setToBeRefunded] = useState([]);
    const [rest, setRest] = useState([]);
    const [outdated, setOutdated] = useState([]);
    const [fetchAllInvoices, setFetchAllInvoices] = useState(false);

    useEffect(() => {
        const promises = [api.get(`${spleisUrl}/api/admin/invoice/${fetchAllInvoices ? '?allInvoices=true' : ''}`)];
        if (fetchAllInvoices) {
            promises.push(api.get(`${spleisUrl}/api/admin/invoice/old`));
        }
        Promise.all(promises)
            .then((values) => {
                const invoices = values?.[0]?.db || [];
                const filterNotSent = invoices.filter((i) => !i.sent_at && !i.creditnote_at);
                setNotSent(filterNotSent);
                const filterNotPaid = invoices.filter((i) => i.sent_at && !i.paid_at && !i.creditnote_at);
                setNotPaid(filterNotPaid);
                setToBeRefunded(invoices.filter((i) => i.to_be_refunded));
                setRest(invoices.filter((i) => filterNotSent.indexOf(i) === -1 && filterNotPaid.indexOf(i) === -1));
                setOutdated(values?.[1] || []);
            })
            .catch((err) => showError('Feil ved henting av fakturaer'));
    }, [fetchAllInvoices]);

    return (
        <TaskContainer>
            <Helmet>
                <title>Fakturaoversikt</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">Fakturaoversikt</h1>
            </header>
            <div className="task-actions">
                <InvoiceActions />
            </div>
            <div className="task-body">
                <div>
                    {notSent?.length > 0 && <InvoiceList title="Nye som skal sendes" invoices={notSent} />}
                    {toBeRefunded?.length > 0 && <InvoiceList title="Skal refunderes" invoices={toBeRefunded} />}
                    {notPaid?.length > 0 && <InvoiceList title="Venter på innbetaling" invoices={notPaid} />}
                    {rest?.length > 0 && <InvoiceList title="Betalte / kreditnota" invoices={rest} />}
                    {outdated?.length > 0 && (
                        <InvoiceList
                            title="Utdaterte"
                            invoices={outdated}
                            description={'Fakturaer til prosjekter som ble avsluttet for over en måned siden.'}
                        />
                    )}
                </div>
                {!fetchAllInvoices && (
                    <div>
                        <Button onClick={() => setFetchAllInvoices(true)}>Hent betalte/gamle/utdaterte</Button>
                    </div>
                )}
            </div>
        </TaskContainer>
    );
};

export default Invoices;
